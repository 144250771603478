import { Col, Empty, Row } from "antd";
import { MapContainer, TileLayer } from "react-leaflet";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";

import CustomCard from "./CustomCard";
import "./MapViewSummaryCard.scss";
import { EstateInfoResponse } from "../../types/EstateDetailsResponse";

const MapViewSummaryCard = ({
	estateInfrmation,
}: {
	estateInfrmation: EstateInfoResponse;
}) => {
	return (
		<CustomCard>
			{estateInfrmation &&
			estateInfrmation.estateId &&
			estateInfrmation.longitude &&
			estateInfrmation.latitude &&
			estateInfrmation.zoomLevel ? (
				<Row className="map-view-summary-row">
					<Col span={24}>
						<div className="map-view-summary-map-section">
							<MapContainer
								center={[1.3521, 103.8198]}
								zoom={12}
								scrollWheelZoom={false}
								className="map-container-view"
							>
								<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
							</MapContainer>
						</div>
					</Col>
					<Col span={24}>
						<Title className="inspection-summary-title" level={3}>
							{estateInfrmation.estateName}
						</Title>
						<Link to={`/inspections-summary-map/${estateInfrmation.estateId}`}>
							<p className="map-view-summary-map-sub-text">Click to view map</p>
						</Link>
					</Col>
				</Row>
			) : (
				<Empty description="No map information found"></Empty>
			)}
		</CustomCard>
	);
};

export default MapViewSummaryCard;

import axios from 'axios';
import { BASE_URL, REACT_TOKEN_AUTH } from '../constants/constants';

export const AuthService = {
	isAuthenticated: () => {
		return !!localStorage.getItem(REACT_TOKEN_AUTH);
	},
	authenticate: async (
		userName: any,
		password: any,
		successCallback: any,
		errorCallback: any
	) => {
		try {
			const loginResult = await axios.post(
				`${BASE_URL}/api/v1.0/cloud/auth/login`,
				{
					email: userName,
					password: password,
				}
			);
			if (loginResult.data && loginResult.data.accessToken) {
				localStorage.setItem(
					REACT_TOKEN_AUTH,
					JSON.stringify(loginResult.data)
				);
				successCallback();
			} else {
				errorCallback();
			}
		} catch (error) {
			console.error(error);
			errorCallback();
		}
	},
	signout() {
		localStorage.setItem(REACT_TOKEN_AUTH, '');
	},
	getLoggedInUserName: () => {
		let usrObj = JSON.parse(
			(localStorage && localStorage.getItem(REACT_TOKEN_AUTH)) ?? ''
		);
		if (usrObj) return `${usrObj.first_name} ${usrObj.last_name}`;
		else return '';
	},
};

import { useEffect, useState } from "react";
import { Col, Empty, Row, TablePaginationConfig } from "antd";
import Title from "antd/lib/typography/Title";
import { Typography } from "antd";
import { Link, useParams } from "react-router-dom";

import CustomTable from "../../components/CustomTable/CustomTable";
import CustomCard from "../../components/CustomCard/CustomCard";
import DefectDetail from "../../components/DefectDetail/DefectDetail";
import "./RoadInspectionList.scss";
import endpointConfig from "../../config/endpointConfig";
import { CloudResponse } from "../../types/CloudResponse";
import { EstateInfoResponse } from "../../types/EstateDetailsResponse";
import api from "../../config/axiosConfig";
import {
	TableDataBase,
	TableDataResponseWithTripIds,
} from "../../types/TableDataBase";
import { RoadInspectionDefectData } from "../../types/RoadInspectionDefectData";
import { DefectTypeResponse } from "../../types/DefectTypeResponse";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../components/Notification/Notification";
import { formatDate } from "../../helpers/dateHelper";
import {
	filterObjectToUrlParam,
	transformRoadDefect,
} from "../../helpers/dtoTransformer";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { RoadDefectDetailResponse } from "../../types/RoadDefectDetailResponse";
import { logUnhandledErrors } from "../../helpers/errorLogHelper";
import { DefectStatusResponse } from "../../types/DefectStatusResponse";
import TripSummaryPopup from "../../components/TripSummaryPopup/TripSummaryPopup";
import { TripSummaryInfo } from "../../types/TripSummaryInfo";

const { Text } = Typography;

const RoadInspectionList = () => {
	const { estateId } = useParams<{ estateId: string }>();
	const [isDetailsLoading, setDetailsLoading] = useState<boolean>(false);
	const [estateInfo, setEstateInfo] = useState<EstateInfoResponse>();
	const [defectStatus, setDefectStatus] = useState<DefectStatusResponse[]>();
	const [defectTypes, setDefectTypes] = useState<DefectTypeResponse[]>();
	const [tripFilter, setTripFilter] = useState<number[]>();
	const [defectDetails, setDefectDetails] =
		useState<RoadDefectDetailResponse>();
	const [formDisabled, setFormStatus] = useState(true);
	const [roadInspectionDefectList, setRoadInspectionDefectList] =
		useState<TableDataBase<RoadInspectionDefectData>>();
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [tripSummaryData, setTripSummaryData] = useState<TripSummaryInfo>();
	const [gridColumn, setGridColumn] = useState<any[]>([
		{
			title: "Defect Id",
			dataIndex: "defectId",
			key: "defectId",
			sorter: false,
			responsive: ["md"],
		},
		{
			title: "Trip Id",
			dataIndex: "tripId",
			key: "tripId",
			sorter: false,
			filterMode: "menu",
			filterSearch: true,
			filters: [],
			responsive: ["md"],
			render: (tripId: string) => {
				return (
					<Link
						to="#"
						title="Click to view trip summary"
						onClick={(e) => {
							getTripSummaryData(tripId);
							e.preventDefault();
						}}
					>
						{tripId}
					</Link>
				);
			},
		},
		{
			title: "Date",
			dataIndex: "defectDate",
			key: "defectDate",
			sorter: true,
			render: (date: string) => {
				return formatDate(date);
			},
			responsive: ["sm"],
		},
		{
			title: "Defect Type",
			dataIndex: "defectType",
			key: "defectType",
			sorter: true,
			filters: [],
		},
		{
			title: "Status",
			dataIndex: "defectStatus",
			key: "defectStatus",
			sorter: true,
			filters: [],
		},
		{
			title: "Inspector Name",
			dataIndex: "inspectorName",
			key: "inspectorName",
			sorter: true,
			responsive: ["md"],
		},
		{
			title: "Ticket Opened",
			dataIndex: "openedDays",
			key: "openedDays",
			sorter: false,
			responsive: ["lg"],
		},
	]);

	useEffect(() => {
		fetchTableData();
		if (estateId) {
			api
				.get(
					`${endpointConfig.GET_ESTATE_DETAILS}`.replace("estate-id", estateId)
				)
				.then((res) => {
					const response: CloudResponse<EstateInfoResponse> = res.data;
					if (response.isSuccess) {
						setEstateInfo(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});

			api
				.get(`${endpointConfig.GET_ALL_DEFECTS_STATUS}`)
				.then((res) => {
					const response: CloudResponse<DefectStatusResponse[]> = res.data;
					if (response.isSuccess) {
						setDefectStatus(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
			api
				.get(`${endpointConfig.GET_ALL_DEFECT_TYPES}`)
				.then((res) => {
					const response: CloudResponse<DefectTypeResponse[]> = res.data;
					if (response.isSuccess) {
						setDefectTypes(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
		}
	}, [estateId]);

	useEffect(() => {
		if (defectStatus) {
			setGridColumn(
				gridColumn.map((x: { key: string }) =>
					x.key === "defectStatus"
						? {
								...x,
								filters: defectStatus.map((x) => ({
									text: x.defectStatus,
									value: x.defectStatusId,
								})),
						  }
						: x
				)
			);
		}
		if (defectTypes) {
			setGridColumn(
				gridColumn.map((x: { key: string }) =>
					x.key === "defectType"
						? {
								...x,
								filters: defectTypes.map((x) => ({
									text: x.defectType,
									value: x.defectTypeId,
								})),
						  }
						: x
				)
			);
		}
	}, [defectStatus, defectTypes]);
	useEffect(() => {
		if (tripFilter) {
			setGridColumn(
				gridColumn.map((x: { key: string }) =>
					x.key === "tripId"
						? {
								...x,
								filters: tripFilter.map((x) => ({
									text: x,
									value: x,
								})),
						  }
						: x
				)
			);
		}
	}, [tripFilter]);

	const fetchTableData = () => {
		setRoadInspectionDefectList((prevState): any => {
			return { ...prevState, loading: true };
		});
		api
			.get(
				`${endpointConfig.GET_ROAD_INSPECTION_DEFECT_DATA}`
					.replace("estate-id", estateId)
					.replace(
						"page-number",
						roadInspectionDefectList?.pagination.current.toString() ?? "1"
					)
					.replace(
						"page-size",
						roadInspectionDefectList?.pagination.pageSize.toString() ?? "10"
					)
					.replace("sort-key", roadInspectionDefectList?.sortKey ?? "")
					.replace(
						"sort-order",
						roadInspectionDefectList?.sortOrder.toString() ?? ""
					)
					.replace(
						"data-filter",
						filterObjectToUrlParam(roadInspectionDefectList?.filter ?? [])
					)
			)
			.then((res) => {
				const response: CloudResponse<
					TableDataResponseWithTripIds<RoadInspectionDefectData>
				> = res.data;
				if (response.isSuccess) {
					const tableData: TableDataBase<RoadInspectionDefectData> = {
						data: response.data.pagedData,
						loading: false,
						pagination: {
							current: parseInt(response.data.currentPage),
							total: response.data.totalRecords,
							pageSize: roadInspectionDefectList?.pagination.pageSize ?? 10,
						},
						sortKey: roadInspectionDefectList?.sortKey.toString() ?? "",
						sortOrder: roadInspectionDefectList?.sortOrder.toString() ?? "",
						filter: roadInspectionDefectList?.filter ?? [],
					};
					setTripFilter(response.data.tripIds);
					setRoadInspectionDefectList(tableData);
				} else {
					setRoadInspectionDefectList({
						data: [],
						loading: false,
						pagination: {
							current: 1,
							total: 0,
							pageSize: roadInspectionDefectList?.pagination.pageSize ?? 10,
						},
						sortKey: roadInspectionDefectList?.sortKey.toString() ?? "",
						sortOrder: roadInspectionDefectList?.sortOrder.toString() ?? "",
						filter: roadInspectionDefectList?.filter ?? [],
					});
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification("Error occurred while processing the request.");
			});
	};
	const handleTableChange = (
		pagination: TablePaginationConfig,
		sorter: SorterResult<object>,
		filter: Record<string, FilterValue | null>
	) => {
		if (roadInspectionDefectList) {
			roadInspectionDefectList.loading = true;
			if (sorter?.order) {
				roadInspectionDefectList.sortKey = sorter.field?.toString() ?? "";
				roadInspectionDefectList.sortOrder =
					sorter.order === "ascend" ? "asc" : "desc";
			} else {
				roadInspectionDefectList.sortKey = "";
			}
			if (filter) {
				roadInspectionDefectList.filter = [];
				for (let key in Object.keys(filter)) {
					const filterValues: FilterValue | null = Object.values(filter)[key];
					if (filterValues) {
						roadInspectionDefectList.filter.push({
							key: Object.keys(filter)[key],
							values: filterValues,
						});
					}
				}
			}
			roadInspectionDefectList.pagination = {
				current: pagination.current ?? 1,
				pageSize: pagination.pageSize ?? 10,
				total: pagination.total ?? 10,
			};
			setRoadInspectionDefectList(roadInspectionDefectList);
			fetchTableData();
		}
	};
	const updateDefectDetails = (
		defectStatusId: number,
		observationNotes: string,
		comments: string
	): void => {
		if (defectDetails?.defectId) {
			api
				.put(`${endpointConfig.UPDATE_ROAD_DEFECT_STATUS}`, {
					defectId: defectDetails?.defectId,
					statusId: defectStatusId,
					comment: comments,
					observationNotes: observationNotes,
				})
				.then((res) => {
					const response: CloudResponse<object> = res.data;
					if (response.isSuccess) {
						getDefectDetails(defectDetails?.defectId);
						setFormStatus(true);
						showSuccessNotification("successfully updated.");
						fetchTableData();
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
		}
	};
	const getDefectDetails = (defectId: string) => {
		if (defectId) {
			setFormStatus(true);
			setDetailsLoading(true);
			api
				.get(
					`${endpointConfig.GET_ROAD_DEFECT_DETAILS}`.replace(
						"defect-id",
						defectId
					)
				)
				.then((res) => {
					const response: CloudResponse<RoadDefectDetailResponse> = res.data;
					if (response.isSuccess) {
						setDefectDetails(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				})
				.finally(() => {
					setDetailsLoading(false);
				});
		}
	};
	const getTripSummaryData = (tripId: string) => {
		api
			.get(`${endpointConfig.GET_TRIP_SUMMARY_DATA}`.replace("trip-id", tripId))
			.then((res) => {
				const response: CloudResponse<TripSummaryInfo> = res.data;
				if (response.isSuccess) {
					setTripSummaryData(response.data);
					setIsModalVisible(true);
				} else {
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification("Error occurred while processing the request.");
			});
	};
	return (
		<div>
			{estateId && parseInt(estateId) ? (
				<>
					<div className="road-inspection-list">
						<Row gutter={[20, 20]}>
							<Col span={24}>
								<Title className="sub-text" level={2}>
									Road Defects
								</Title>
								<p className="sub-text">
									{estateInfo?.estateName} .&nbsp;
									<Text type="danger">
										{roadInspectionDefectList?.pagination
											? roadInspectionDefectList.pagination.total
											: "0"}
										&nbsp;Pending JTC Actions
									</Text>
								</p>
							</Col>
						</Row>
						<Row gutter={[20, 20]}>
							<Col lg={17} md={15} sm={12} xs={24}>
								<CustomCard>
									<CustomTable
										className="road-inspection-table"
										rowClassName={(record: any) =>
											record.defectId === defectDetails?.defectId
												? "selected-table-row"
												: "unselected-table-row"
										}
										dataSource={roadInspectionDefectList?.data}
										columns={gridColumn}
										pagination={roadInspectionDefectList?.pagination}
										loading={roadInspectionDefectList?.loading}
										onChange={(pagination, filter, sort) => {
											handleTableChange(
												pagination,
												Array.isArray(sort) ? sort[0] : sort,
												filter
											);
										}}
										size="middle"
										onRow={(record: any) => ({
											onClick: () => {
												getDefectDetails(record.defectId);
											},
										})}
									/>
								</CustomCard>
							</Col>
							<Col lg={7} md={9} sm={12} xs={24}>
								<CustomCard className="defect-detail-card">
									{defectDetails ? (
										<DefectDetail
											defectDetails={transformRoadDefect(
												defectDetails,
												setFormStatus,
												updateDefectDetails,
												formDisabled,
												defectStatus,
												isDetailsLoading
											)}
										></DefectDetail>
									) : (
										<Empty
											className="empty-msg"
											description="Click On Row to View Defect"
										></Empty>
									)}
								</CustomCard>
							</Col>
						</Row>
					</div>
					<TripSummaryPopup
						tripSummaryInfo={tripSummaryData}
						isModalVisible={isModalVisible}
						setIsModalVisible={setIsModalVisible}
					></TripSummaryPopup>
				</>
			) : (
				<Empty description="Invalid estate id"></Empty>
			)}
		</div>
	);
};

export default RoadInspectionList;

const endpointConfig = {
	GET_ESTATES: '/estates',
	GET_ESTATE_DETAILS: '/estate?estateId=estate-id',
	GET_FMC_DETAILS: '/estate-level-defect-metrics?estateId=estate-id',
	GET_DEFECT_REPORT:
		'/generate-defect-report?tripId=trip-id&defectTypeId=defect-type-id',
	GET_ROAD_INSPECTION_SUMMARY:
		'/road-inspection-summary?estateId=estate-id&timestamp=time-stamp',
	GET_TREE_INSPECTION_SUMMARY:
		'/tree-inspection-summary?estateId=estate-id&timestamp=time-stamp',
	GET_ROAD_INSPECTION_DEFECT_DATA:
		'/road-inspection-defects?estateId=estate-id&page=page-number&pageSize=page-size&sortkey=sort-key&sortDirection=sort-orderdata-filter',
	GET_ROAD_DEFECT_DETAILS: '/road-inspection-defect?defectId=defect-id',
	GET_ALL_DEFECTS_STATUS: '/defect-status',
	GET_ALL_DEFECT_TYPES: '/defect-types',
	UPDATE_ROAD_DEFECT_STATUS: '/update-road-defect',
	GET_TREE_INSPECTION_DEFECT_DATA:
		'/tree-inspection-defects?estateId=estate-id&page=page-number&pageSize=page-size&sortkey=sort-key&sortDirection=sort-orderdata-filter',
	GET_TREE_DEFECT_DETAILS: '/tree-inspection-defect?defectId=defect-id',
	UPDATE_TREE_DEFECT_STATUS: '/update-tree-defect',
	GET_ALL_DEFECT_SUMMARY:
		'/all-defect-summary?estateId=estate-id&neLat=ne-lat&neLon=ne-lon&swLat=sw-lat&swLon=sw-lon',
	GET_ALL_DEFECT_SUMMARY_BY_TRIP_ID:
		'/all-defect-summary?estateId=estate-id&neLat=ne-lat&neLon=ne-lon&swLat=sw-lat&swLon=sw-lon&tripId=trip-id',
	GET_ALL_DEFECTS_BY_CATEGORY:
		'/defect-list-by-defect-category?estateId=estate-id&defectTypeId=defect-type-id&neLat=ne-lat&neLon=ne-lon&swLat=sw-lat&swLon=sw-lon',
	GET_ALL_DEFECTS_BY_CATEGORY_AND_TRIP_ID:
		'/defect-list-by-defect-category?estateId=estate-id&defectTypeId=defect-type-id&neLat=ne-lat&neLon=ne-lon&swLat=sw-lat&swLon=sw-lon&tripId=trip-id',
	GET_MAP_TILE_VIEW: '/all-tile-information?estateId=estate-id',
	GET_TRIP_SUMMARY_DATA: '/trip-info?tripId=trip-id',
	GET_TRIP_SUMMARY_LIST:
		'/road-trip-inspection-defect-summary?estateId=estate-id&statusId=status-id',
	GET_STATUSES: '/road-inspection-statuses',
	GET_TRIP_DETAILS: '/trip-details-by-estate?estateId=estate-id',
	GET_ESTATE_METRICS: '/estate-metrics',
	GET_ESTATE_RESPONSE_TIME: '/estate-response-time',
	GET_ESTATE_DEFECTS_FIXED: '/estate-defects-fixed',
	GET_USER_TRIPS: '/trips-by-user?estateId=estate-id',
	GET_REPORT_GENERATION_TRIP_SUMMARY:
		'/report-generation-trip-summary?tripId=trip-id&verified=true',
	GET_REPORT_GENERATION_DEFECT_SUMMARY:
		'/report-generation-defect-summary?tripId=trip-id&defectStatusId=defect-status-id',
};

export default endpointConfig;

import { Switch, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { DivIcon, divIcon, PathOptions } from 'leaflet';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
	LayerGroup,
	MapContainer,
	Marker,
	Popup,
	TileLayer,
	GeoJSON,
	// ImageOverlay,
	LayersControl,
	//useMapEvents,
} from 'react-leaflet';
import { Link } from 'react-router-dom';
import { commonMapMarker } from '../../resources/icons/common-map-marker';
import { MapProps } from '../../types/MapProps';
import './CustomMap.scss';
import { REACT_TOKEN_AUTH } from '../../constants/constants';
const { Text } = Typography;
const legendOptions: Record<string, any> = {
	'road-markings': {
		name: 'Road Marking',
		color: 'var(--dashboard-road-marking-color)',
	},
	// "road-surfaces": {
	// 	name: "Road Surfaces",
	// 	color: "var(--dashboard-road-surfaces-color)",
	// },
	kerbs: {
		name: 'Kerbs',
		color: 'var(--dashboard-kerbs-color)',
	},
	'pothole/cracks': {
		name: 'Pothole/Cracks',
		color: 'var(--dashboard-pothole-color)',
	},
	encroachment: {
		name: 'Encroachment',
		color: 'var(--dashboard-encroachment-color)',
	},
	// tree: {
	// 	name: "Tree",
	// 	color: "var(--dashboard-tree-color)",
	// },
	'grating-covers': {
		name: 'Grating Covers',
		color: 'var(--dashboard-grating-cover-color)',
	},
	'road-signage': {
		name: 'Road Signage',
		color: 'var(--dashboard-road-signage-color)',
	},
	'overgrown-vegetation': {
		name: 'Overgrown Vegetation',
		color: 'var(--dashboard-overgrown-vegetation-color)',
	},
	'road-debris-and-spillage': {
		name: 'Road Debris and Spillage',
		color: 'var(--dashboard-road-debris-color)',
	},
	'broken-footpath': {
		name: 'Broken Footpath',
		color: 'var(--dashboard-broken-footpath-color)',
	},
	litter: {
		name: 'Litter',
		color: 'var(--dashboard-litter-color)',
	},
};

const geoJsonLayerStyling = (
	feature: GeoJSON.Feature<GeoJSON.Geometry, any> | undefined
): PathOptions => {
	if (feature && feature.properties) {
		//console.log(feature.properties);
		// console.log(
		//   `--dashboard-tree-heatmap-color${Math.ceil(
		//     feature.properties["percentage"] / 7
		//   )}`
		// );
		const _fillColor =
			feature.properties['percentage'] > 0
				? feature.properties['percentage'] < 7
					? 7
					: feature.properties['percentage']
				: 0;
		// console.log(_fillColor);
		const options: PathOptions = {
			fillColor: `var(--dashboard-tree-heatmap-color${Math.floor(
				_fillColor / 7
			)})`,
			color: 'var(--dashboard-tree-heatmap-feature-border-color)',
			fillOpacity: 0.75,
		};
		return options;
	} else {
		return {};
	}
};
const geoJsonLayerClick = (e: any) => {
	// console.log(e);
};

const CustomMap = (props: MapProps) => {
	const [tileVisible, setTileVisible] = useState(false);
	let [user_id, setUserID] = useState(0);
	const popUpRef = useRef<any[]>([]);

	useEffect(() => {
		let auth: any = localStorage.getItem(REACT_TOKEN_AUTH);
		if (auth) {
			auth = JSON.parse(auth);
			let userid = auth['user_id'];
			setUserID(userid);
		}
	}, []);

	let markerOptions: { [name: string]: DivIcon } = {
		'road-markings': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-road-marking-color)'
			),
		}),
		// "road-surfaces": divIcon({
		// 	className: "map-marker-common-icon",
		// 	html: commonMapMarker.replace(
		// 		"marker-color",
		// 		"var(--dashboard-road-surfaces-color)"
		// 	),
		// }),
		kerbs: divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-kerbs-color)'
			),
		}),
		'pothole/cracks': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-pothole-color)'
			),
		}),
		encroachment: divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-encroachment-color)'
			),
		}),
		// tree: divIcon({
		// 	className: "map-marker-tree-icon",
		// }),
		'grating-covers': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-grating-cover-color)'
			),
		}),
		'road-signage': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-road-signage-color)'
			),
		}),
		'overgrown-vegetation': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-overgrown-vegetation-color)'
			),
		}),
		'road-debris-and-spillage': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-road-debris-color)'
			),
		}),
		'broken-footpath': divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-broken-footpath-color)'
			),
		}),
		litter: divIcon({
			className: 'map-marker-common-icon',
			html: commonMapMarker.replace(
				'marker-color',
				'var(--dashboard-litter-color)'
			),
		}),
	};

	if (user_id && user_id === 3) {
		markerOptions = {
			'road-markings': divIcon({
				className: 'map-marker-common-icon',
				html: commonMapMarker.replace(
					'marker-color',
					'var(--dashboard-road-marking-color)'
				),
			}),
			// "road-surfaces": divIcon({
			// 	className: "map-marker-common-icon",
			// 	html: commonMapMarker.replace(
			// 		"marker-color",
			// 		"var(--dashboard-road-surfaces-color)"
			// 	),
			// }),
			kerbs: divIcon({
				className: 'map-marker-common-icon',
				html: commonMapMarker.replace(
					'marker-color',
					'var(--dashboard-kerbs-color)'
				),
			}),
			'pothole/cracks': divIcon({
				className: 'map-marker-common-icon',
				html: commonMapMarker.replace(
					'marker-color',
					'var(--dashboard-pothole-color)'
				),
			}),
			encroachment: divIcon({
				className: 'map-marker-common-icon',
				html: commonMapMarker.replace(
					'marker-color',
					'var(--dashboard-encroachment-color)'
				),
			}),
			// tree: divIcon({
			// 	className: 'map-marker-tree-icon',
			// }),
			'grating-covers': divIcon({
				className: 'map-marker-common-icon',
				html: commonMapMarker.replace(
					'marker-color',
					'var(--dashboard-grating-cover-color)'
				),
			}),
			litter: divIcon({
				className: 'map-marker-common-icon',
				html: commonMapMarker.replace(
					'marker-color',
					'var(--dashboard-litter-color)'
				),
			}),
		};
	} else {
		if (props.estateInfo?.estateId === 3) {
			markerOptions = {
				'road-markings': divIcon({
					className: 'map-marker-common-icon',
					html: commonMapMarker.replace(
						'marker-color',
						'var(--dashboard-road-marking-color)'
					),
				}),
				// "road-surfaces": divIcon({
				// 	className: "map-marker-common-icon",
				// 	html: commonMapMarker.replace(
				// 		"marker-color",
				// 		"var(--dashboard-road-surfaces-color)"
				// 	),
				// }),
				kerbs: divIcon({
					className: 'map-marker-common-icon',
					html: commonMapMarker.replace(
						'marker-color',
						'var(--dashboard-kerbs-color)'
					),
				}),
				'pothole/cracks': divIcon({
					className: 'map-marker-common-icon',
					html: commonMapMarker.replace(
						'marker-color',
						'var(--dashboard-pothole-color)'
					),
				}),
				encroachment: divIcon({
					className: 'map-marker-common-icon',
					html: commonMapMarker.replace(
						'marker-color',
						'var(--dashboard-encroachment-color)'
					),
				}),
				// tree: divIcon({
				// 	className: "map-marker-tree-icon",
				// }),
				'grating-covers': divIcon({
					className: 'map-marker-common-icon',
					html: commonMapMarker.replace(
						'marker-color',
						'var(--dashboard-grating-cover-color)'
					),
				}),
				litter: divIcon({
					className: 'map-marker-common-icon',
					html: commonMapMarker.replace(
						'marker-color',
						'var(--dashboard-litter-color)'
					),
				}),
			};
		}
	}
	function MapComponent() {
		//const mapEvents = useMapEvents({
		// zoomend: () => {
		//   props.setMapBounds && props.setMapBounds(mapEvents.getBounds());
		// },
		// dragend: () => {
		//   props.setMapBounds && props.setMapBounds(mapEvents.getBounds());
		// },
		// });
		return null;
	}
	return props.estateInfo ? (
		<>
			<MapContainer
				//whenCreated={setMap}
				center={[props.estateInfo.latitude, props.estateInfo.longitude]}
				zoom={props.estateInfo.zoomLevel}
				maxZoom={18}
				minZoom={7}
				scrollWheelZoom={true}
				style={{ height: '80vh', minHeight: '80vh', width: '100%' }}
			>
				<MapComponent />
				<Switch
					style={{
						right: '5px',
						top: '5px',
						position: 'absolute',
						zIndex: 1000,
					}}
					unCheckedChildren="Tile"
					checked={tileVisible}
					// onChange={() => {
					//   //setTileChanging(true);
					//   setTileVisible(true);
					// }}
					onClick={() => {
						setTileVisible(!tileVisible);
					}}
				></Switch>
				<LayersControl position="topright">
					<LayersControl.BaseLayer checked name="OpenStreetMap">
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					</LayersControl.BaseLayer>
					{tileVisible && props.tileInformation && (
						<GeoJSON
							key="geo-json"
							data={props.tileInformation}
							style={geoJsonLayerStyling}
							eventHandlers={{
								click: geoJsonLayerClick,
								loading: (x) => {
									// console.log(x);
								},
							}}
						></GeoJSON>
					)}
					{props?.defectList &&
						props.defectList.map((defect, i) => {
							return (
								<LayerGroup key={i}>
									<Marker
										icon={markerOptions[defect.defectTypeKey]}
										position={[defect.latitude, defect.longitude]}
									>
										<Popup
											ref={(ref) =>
												popUpRef.current.push({
													id: `${defect.defectTypeKey}_${defect.defectId}`,
													ref: ref,
												})
											}
										>
											<Link
												to={`/inspections-summary-map/${props.estateInfo?.estateId}/${defect.defectSegment}/${defect.defectTypeId}/${defect.defectId}`}
											>
												<Text type="success">{defect.defectName}</Text>
											</Link>
											&nbsp;.&nbsp;
											<Text type="secondary">{defect.defectStatus}</Text>
										</Popup>
									</Marker>
								</LayerGroup>
							);
						})}

					<LayersControl.BaseLayer name="Satallite View">
						<TileLayer
							url="https://jtcsmartvision.blob.core.windows.net/smartvision-dev/MapImages/MapTiles-Rgb/{z}/{x}/{y}.png"
							opacity={1}
							zIndex={20}
							tms={true}
						/>
					</LayersControl.BaseLayer>
				</LayersControl>
				<div className="map-legend">
					{Object.keys(markerOptions).map((e) => {
						return (
							<div className="map-legend-item">
								<div
									className="legend-square"
									style={{ backgroundColor: legendOptions[e].color }}
								></div>
								{legendOptions[e]['name'].toString()}
							</div>
						);
					})}
				</div>
			</MapContainer>
		</>
	) : (
		<Title>Loading...</Title>
	);
};

export default CustomMap;

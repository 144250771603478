import {
	Col,
	Row,
	Typography,
	Image,
	Statistic,
	Divider,
	Form,
	Popconfirm,
	Spin,
	Carousel,
} from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import "./DefectDetail.scss";
import DefectDetailForm from "../DefectDetailForm/DefectDetailForm";
import { InspectionTypes } from "../../types/InspectionTypesEnum";
import Title from "antd/lib/typography/Title";
import noImage from "../../resources/No_Image_Available.jpg";
import { DefectDetailProps } from "../../types/DefectDetailProps";
import { DefectTypes } from "../../types/DefectTypesEnum";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

const { Text } = Typography;

const DefectDetail = ({
	defectDetails,
}: {
	defectDetails: DefectDetailProps;
}) => {
	const isTree = defectDetails.type === InspectionTypes.TreeInspection;
	const [form] = Form.useForm();
	const updateDefectDetails = (): void => {
		defectDetails.updateDefectDetails(
			form.getFieldValue("statusId"),
			form.getFieldValue("observationNotes"),
			form.getFieldValue("comments")
		);
	};
	return defectDetails.loading ? (
		<div className="flex-container">
			{" "}
			<Spin size="large" />
		</div>
	) : (
		<div className="defect-detail">
			<Row>
				<Col span={20}>
					<Title level={4} className="sub-text">
						{isTree ? defectDetails.failurePart : defectDetails.defectType}
					</Title>
					<p className="sub-text">
						<Text type="secondary">
							{new Date(defectDetails.defectDate)
								.toISOString()
								.substring(0, 10)}
						</Text>
						.<Text type="danger"> PA by JTC</Text>
					</p>
				</Col>
				<Col span={4}>
					<div className="defect-details-icon-section">
						{defectDetails.formDisabled ? (
							<EditOutlined
								onClick={() => defectDetails.setFormStatus(false)}
								className="action-icon"
							/>
						) : (
							<Popconfirm
								title="Are you sure to update this defect?"
								onConfirm={() => updateDefectDetails()}
								onCancel={() => defectDetails.setFormStatus(true)}
								okText="Yes"
								cancelText="No"
							>
								<SaveOutlined className="action-icon" />
							</Popconfirm>
						)}
						{isTree ? (
							<Text className="defect-impact-text" type="danger">
								{defectDetails.riskRating}
							</Text>
						) : null}
					</div>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Image.PreviewGroup>
						<Carousel arrows={true}>
							<div className="defect-image">
								<Image
									//height={200}
									style={{ objectFit: "cover", width: "100%", maxHeight: 200 }}
									src={defectDetails.inputImage ?? noImage}
									alt="Defect Image"
								/>
							</div>
							<div className="defect-image">
								<Image
									//height={200}
									style={{ objectFit: "cover", width: "100%", maxHeight: 200 }}
									src={defectDetails.outputImage ?? noImage}
									alt="Defect Image"
								/>
							</div>
							<div className="defect-image">
								<Image
									//height={200}
									style={{ objectFit: "cover", width: "100%", maxHeight: 200 }}
									src={defectDetails.correctedImage ?? noImage}
									alt="Defect Image"
								/>
							</div>
						</Carousel>
					</Image.PreviewGroup>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<div className="map-view-summary-map-section">
						<MapContainer
							center={[
								defectDetails.latitude
									? (defectDetails.latitude.toFixed(7) as unknown as number)
									: 1.29027,
								defectDetails.longitide
									? (defectDetails.longitide.toFixed(7) as unknown as number)
									: 103.851959,
							]}
							zoom={13}
							scrollWheelZoom={false}
							className="map-container-view"
						>
							<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
							<Marker
								key={"marker"}
								position={[
									defectDetails.latitude
										? (defectDetails.latitude.toFixed(7) as unknown as number)
										: 1.29027,
									defectDetails.longitide
										? (defectDetails.longitide.toFixed(7) as unknown as number)
										: 103.851959,
								]}
							></Marker>
						</MapContainer>
					</div>
				</Col>
			</Row>
			{defectDetails.defectTypeKey === DefectTypes.TreeInspection && (
				<>
					<Row>
						<Col span={24}>
							<Text>
								Tree Id : <strong>{defectDetails.treeId}</strong>
							</Text>
						</Col>
						<Col span={24}>
							<Text>
								Species : <strong>{defectDetails.species}</strong>
							</Text>
						</Col>
						<Divider></Divider>
					</Row>
					<Row>
						<Col className="flex-container " span={11}>
							<Statistic
								title="Height"
								value={
									defectDetails.height ? defectDetails.height.toFixed(1) : "..."
								}
								suffix={
									<Text> {defectDetails.height ? <div>m</div> : ""}</Text>
								}
							/>
						</Col>
						<Col span={2}>
							<Divider className="vertical-devider" type="vertical" />
						</Col>
						<Col className="flex-container " span={11}>
							<Statistic
								title="Girth"
								value={
									defectDetails.girth ? defectDetails.girth.toFixed(1) : "..."
								}
								suffix={<Text> {defectDetails.girth ? <div>m</div> : ""}</Text>}
							/>
						</Col>
					</Row>
				</>
			)}

			{defectDetails.defectTypeKey === DefectTypes.Pothole && (
				<>
					<Row>
						<Col className="flex-container" span={24}>
							<Statistic
								title="Area"
								value={
									defectDetails.area ? defectDetails.area.toFixed(1) : "..."
								}
								suffix={
									<Text>
										{" "}
										{defectDetails.area ? (
											<div>
												m<sup>2</sup>
											</div>
										) : (
											""
										)}
									</Text>
								}
							/>
						</Col>
						{/* <Col span={2}>
              <Divider className="vertical-devider" type="vertical" />
            </Col>
            <Col className="flex-container " span={11}>
              <Statistic
                title="Depth"
                value={
                  defectDetails.depth ? defectDetails.depth.toFixed(1) : "..."
                }
                suffix={<Text> {defectDetails.depth ? <div>m</div> : ""}</Text>}
              />
            </Col> */}
					</Row>
				</>
			)}

			{defectDetails.defectTypeKey === DefectTypes.Kerbs && (
				<>
					<Row>
						<Col className="flex-container" span={24}>
							<Statistic
								title="Area"
								value={
									defectDetails.area ? defectDetails.area.toFixed(1) : "..."
								}
								suffix={
									<Text>
										{" "}
										{defectDetails.area ? (
											<div>
												m<sup>2</sup>
											</div>
										) : (
											""
										)}
									</Text>
								}
							/>
						</Col>
					</Row>
				</>
			)}

			{defectDetails.defectTypeKey === DefectTypes.RoadMarking && (
				<>
					<Row>
						<Col className="flex-container" span={24}>
							<Statistic
								title="Faded Score"
								value={
									defectDetails.fadedScore
										? defectDetails.fadedScore === 1
											? "Completely"
											: (defectDetails.fadedScore * 100).toFixed(0)
										: "..."
								}
								suffix={
									<Text>
										{" "}
										{defectDetails.fadedScore !== 1 ? <div>%</div> : ""}
									</Text>
								}
							/>
						</Col>
						{/* <Row>
            <Col span={24}>
              <p className="sub-text">
                Faded Score :{" "}
                {defectDetails.fadedScore
                  ? defectDetails.fadedScore === 1
                    ? "Completely Faded"
                    : (defectDetails.fadedScore * 100).toFixed(0) + "%"
                  : "..."}
              </p>
            </Col> */}
					</Row>
				</>
			)}

			<Row>
				<Col span={24}>
					<DefectDetailForm
						defectStatus={defectDetails.defectStatus}
						comments={defectDetails.comments}
						observationNotes={defectDetails.observationNotes}
						statusId={defectDetails.statusId}
						formDisabled={defectDetails.formDisabled}
						form={form}
					></DefectDetailForm>
				</Col>
			</Row>
		</div>
	);
};

export default DefectDetail;

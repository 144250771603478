import styled from "styled-components";
import { Table } from "antd";

const CustomTable = styled(Table)`
margin-top:5px;
.ant-table-row {
  cursor:pointer;
}
.ant-table-thead {
    background-color: #DADCE0;
    color:#000
  }
  .ant-table-thead > tr > th {
    background-color: #DADCE0;
    color:#000
  }
  .ant-table-tbody > .ant-table-row {
    background-color:rgba(241, 243, 244, 0.4);
  }
}
.selected-table-row {
  background-color: var(--dashboard-color-theme-blue) !important;
  color: white !important;
  a {
    color: white !important;
  }
}
.unselected-table-row:hover {
  background-color: var(--dashboard-color-theme-blue) !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: var(--dashboard-color-mid-grey) !important;
}
`;

export default CustomTable;

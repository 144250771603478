import { toast } from "react-toastify";

const showSuccessNotification = (msg?: string): any => {
  toast.success(msg?msg:'action successful', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};


const showErrorNotification = (msg?: string): any => {
  toast.error(msg?msg:'Error occured', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {showErrorNotification , showSuccessNotification}
import React, { useState } from "react";
import "./Login.scss";

// import loginBg from '../../resources/images/loginbg.jpg';

import { AuthService } from "../../services/authService";
import { useLocation, withRouter } from "react-router-dom";
import { History } from "history";

interface Props {
  history: History;
}

const Login: React.FC<Props> = ({ history }) => {
  let location: any = useLocation();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [invalidCreds, setInvalidCreds] = useState<boolean>(false);

  let { from } = location.state || { from: { pathname: "/" } };

  return (
    //style={{ backgroundImage: `url(${loginBg})` }}
    <div className="Login">
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <div className="session">
        <div className="left"></div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            AuthService.authenticate(
              userName,
              password,
              () => {
                history.push(from);
              },
              () => setInvalidCreds(true)
            );
          }}
          className="log-in"
        >
          <h4>
            Smartvision <span>Login</span>
          </h4>
          <p>Welcome back! Log in to your account.</p>
          <div className="floating-label">
            <input
              placeholder="Username"
              onChange={(e) => setUserName(e.target.value)}
              type="text"
              name="email"
              id="email"
            />
            <label>Username:</label>
            <div className="icon"></div>
          </div>
          <div className="floating-label">
            <input
              placeholder="Password"
              type="password"
              name="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Password:</label>
            <div className="icon"></div>
          </div>
          {invalidCreds && (
            <div className="error-message my-2 p-2 w-100">
              * Username or Password is not valid
            </div>
          )}
          <button type="submit">Log in</button>
        </form>
      </div>

      {/* <div classNameName="loginBox">
        <div classNameName="avatar">
          <img
            src="worksafe_logo.png"
            alt="WorkSafe Logo"
            width="100%"
            height="auto"
          ></img>
        </div>
        <div classNameName="title">
          <p>WorkSafe</p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            AuthService.authenticate(
              userName,
              password,
              () => {
                history.push(from);
              },
              () => setInvalidCreds(true)
            );
          }}
        >
          <div classNameName="inputField">
            <input
              type="text"
              name="name"
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div classNameName="inputField">
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <button type="submit">Login</button>
          {invalidCreds ? (
            <div classNameName="error-message my-2 p-2 w-100">
              * Username or Password is not valid
            </div>
          ) : null}
        </form>
      </div> */}
    </div>
  );
};
export default withRouter(Login);

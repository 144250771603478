import { DefectDetailProps } from "../types/DefectDetailProps";
import { DefectStatusResponse } from "../types/DefectStatusResponse";
import { InspectionTypes } from "../types/InspectionTypesEnum";
import { RoadDefectDetailResponse } from "../types/RoadDefectDetailResponse";
import { TableFilter } from "../types/TableDataBase";
import { TreeDefectDetailResponse } from "../types/TreeDefectDetailResponse";

export const transformRoadDefect = (
	defectDetails: RoadDefectDetailResponse,
	setFormStatus: (status: boolean) => void,
	updateDefectDetails: (
		defectStatusId: number,
		observationNotes: string,
		comments: string
	) => void,
	formDisabled: boolean,
	defectStatus?: DefectStatusResponse[],
	isDetailsLoading?: boolean
): DefectDetailProps => {
	const response: DefectDetailProps = {
		defectStatus: defectStatus ?? [],
		type: InspectionTypes.RoadInspection,
		area: defectDetails.area,
		comments: defectDetails.comments,
		defectDate: defectDetails.defectDate,
		defectType: defectDetails.defectType,
		depth: defectDetails.depth,
		outputImage: defectDetails.outputImage,
		correctedImage: defectDetails.correctedImage,
		inputImage: defectDetails.inputImage,
		latitude: defectDetails.latitude,
		location: defectDetails.landmark,
		longitide: defectDetails.longitude,
		observationNotes: defectDetails.notes,
		statusId: defectDetails.defectStatusId,
		formDisabled: formDisabled,
		defectTypeKey: defectDetails.defectTypeKey,
		fadedScore: defectDetails.fadedScore,
		loading: isDetailsLoading ?? false,
		setFormStatus: setFormStatus,
		updateDefectDetails: updateDefectDetails,
	};
	return response;
};

export const transformTreeDefect = (
	defectDetails: TreeDefectDetailResponse,
	setFormStatus: (status: boolean) => void,
	updateDefectDetails: (
		defectStatusId: number,
		observationNotes: string,
		comments: string
	) => void,
	formDisabled: boolean,
	defectStatus?: DefectStatusResponse[],
	isDetailsLoading?: boolean
): DefectDetailProps => {
	const response: DefectDetailProps = {
		defectStatus: defectStatus ?? [],
		type: InspectionTypes.TreeInspection,
		comments: defectDetails.comments,
		defectDate: defectDetails.defectDate,
		defectType: defectDetails.defectType,
		outputImage: defectDetails.image,
		inputImage: defectDetails.image,
		correctedImage: defectDetails.image,
		latitude: defectDetails.latitude,
		location: defectDetails.landmark,
		longitide: defectDetails.longitude,
		observationNotes: defectDetails.notes,
		statusId: defectDetails.defectStatusId,
		formDisabled: formDisabled,
		girth: defectDetails.girth,
		height: defectDetails.height,
		species: defectDetails.species,
		riskRating: defectDetails.riskRating,
		failurePart: defectDetails.failurePart,
		treeId: defectDetails.treeId,
		defectTypeKey: defectDetails.defectTypeKey,
		loading: isDetailsLoading,
		setFormStatus: setFormStatus,
		updateDefectDetails: updateDefectDetails,
	};
	return response;
};

export const filterObjectToUrlParam = (filters: TableFilter[]) => {
	let filterUrl = "";
	filters.forEach((filter) => {
		filter?.values?.forEach((value) => {
			filterUrl += `&filters=${filter.key}.${value}`;
		});
	});
	console.log(filterUrl);
	return filterUrl;
};

const moment = require("moment");

export const formatDate = (date: string): string => {
  const formattedDate = new Date(date);
  return formattedDate.toISOString().substring(0, 10);
};

export const formatDateTime = (date: string): string => {
  const formattedDateTime = new Date(date);
  const formattedDateTimeString = formattedDateTime.toDateString();
  if (formattedDateTime.getFullYear()<2020){
    return formattedDateTimeString.substring(0,3) + ", " //day
    + formattedDateTimeString.substring(9,11) + " " //date
    + formattedDateTimeString.substring(4, 8) + " " // month
    + "2022" + " " // year
    + formattedDateTime.toTimeString().substring(0, 8);
  } else{
    return formattedDateTimeString.substring(0,3) + ", " //day
    + formattedDateTimeString.substring(9,11) + " " //date
    + formattedDateTimeString.substring(4, 8) + " " // month
    + formattedDateTimeString.substring(11, 15) + " " // year
    + formattedDateTime.toTimeString().substring(0, 8);
  }
  
}

export const verboseDateTime = (date: string): string => {
  // sample date: 2023-02-10T14:39:55.203Z

  if(!date){
    return 'Date not avaliable';
  }
  let [day, time] = date.split("T")
  let [timing, gmt] = time.split(".");
  return moment(`${day} ${timing}`).format('LLL');
}

export const  millisToMinutesAndSeconds = (millis: number): string => {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds as unknown as number < 10 ? '0' : '') + seconds;
}
import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthService } from '../../services/authService';

// A wrapper for <Route> that redirects to the logi
// screen if you're not yet authenticated.
interface Props {
  children?: ReactNode;
  path?: string;
  exact?: boolean;
}
export const PrivateRoute: React.FC<Props> = ({ children, path, exact }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        AuthService.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

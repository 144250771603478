import { PageHeader, Row, Statistic } from "antd";
import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { EstateMetricResponse } from "../../types/EstateMetricResponse";
import CustomCard from "../CustomCard/CustomCard";

const EstateMetricCard = ({
	className,
	EstateMetricProps,
}: {
	EstateMetricProps: EstateMetricResponse;
	className?: string;
}) => {
	const data = EstateMetricProps;
	const barColors = [
		"#80d4be",
		"#8589f1",
		"#dc8cca",
		"#f3cc82",
		"#6E5773",
		"#D45079",
		"#EA9085",
		"#E9E1CC",
	];
	const chartData = data.defectTypes.map((defectType, index) => {
		return {
			name: defectType.defectType,
			count: defectType.defectCount,
			fill: barColors[index % barColors.length],
		};
	});

	const card = (
		<div className={className}>
			<CustomCard>
				<PageHeader
					title={data.estateName}
					extra={[
						<Statistic
							title="Total trips"
							value={data.tripCount}
							className="stats"
						/>,
					]}
				>
					<Row>
						<ResponsiveContainer width="95%" height={300}>
							<BarChart
								data={chartData}
								layout="vertical"
								margin={{ top: 5, right: 20, left: 60, bottom: 5 }}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis type="number" />
								<YAxis dataKey="name" type="category" />
								<Tooltip />
								<Bar dataKey="count" name="Count" radius={[0, 10, 10, 0]} />
							</BarChart>
						</ResponsiveContainer>
					</Row>
				</PageHeader>
			</CustomCard>
		</div>
	);

	return card;
};

export default EstateMetricCard;

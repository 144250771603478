import { Card } from "antd";
import './CustomCard.scss'
import styled from "styled-components";

///purposely left out
// const CustomCard = ({
//   children,
//   loading,
//   className,
// }: {
//   children: any;
//   loading?: boolean;
//   className?: string;
// }): any => {
//   return (
//     <Card loading={loading ?? false} className={className ?? "custom-card"}>
//       {children}
//     </Card>
//   );
// };

const CustomCard = styled(Card)`
    background:#F1F3F4;
    border-radius:15px;
    box-shadow: 0 3px rgba(0, 0, 0, 0.16);
    width:100%;
    .ant-card-body {padding:0;}
    padding-top: 5px;
    padding-left: 5px;
`;

export default CustomCard;

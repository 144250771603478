import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import CalendarOutlined from "@ant-design/icons/lib/icons/CalendarOutlined";
import WarningOutlined from "@ant-design/icons/lib/icons/WarningOutlined";
import { Divider, List, Modal, Tag } from "antd";
import { formatDate } from "../../helpers/dateHelper";

import { TripSummaryInfo } from "../../types/TripSummaryInfo";
import CustomCard from "../CustomCard/CustomCard";
import "./TripSummaryPopup.scss";

const TripSummaryPopup = ({
  tripSummaryInfo,
  isModalVisible,
  setIsModalVisible,
}: {
  tripSummaryInfo?: TripSummaryInfo;
  isModalVisible: boolean;
  setIsModalVisible: (data: boolean) => void;
}) => {
  return (
    <div className="trip-summary-popup">
      {tripSummaryInfo && (
        <Modal
          title={`Trip Summary - ${tripSummaryInfo.tripId}`}
          visible={isModalVisible}
          footer={null}
          closable={true}
          destroyOnClose={true}
          onCancel={() => setIsModalVisible(false)}
        >
          <div className="popup-content">
            <div className="top-cards">
              <div>
                <CustomCard className="popup-card">
                  <CalendarOutlined /> Date
                  <h4 className="subtext">
                    {formatDate(tripSummaryInfo.tripDate) ?? ""}
                  </h4>
                </CustomCard>
              </div>
              <div>
                <CustomCard className="popup-card">
                  <ClockCircleOutlined /> Duration
                  <h4 className="subtext">
                    {tripSummaryInfo.tripDuration.toFixed(0) ?? ""} min(s)
                  </h4>
                </CustomCard>
              </div>
              <div>
                <CustomCard className="popup-card">
                  <WarningOutlined /> Total Defects
                  <h4 className="subtext">
                    {tripSummaryInfo.totalNumberOfDefects.toFixed(0) ?? ""}
                  </h4>
                </CustomCard>
              </div>
            </div>

            <div>
              <Divider>
                <h5>Defect Summary</h5>
              </Divider>
              <List
                itemLayout="horizontal"
                dataSource={tripSummaryInfo.defectInfo}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.defectType}
                      description={item.statusCounts.map((x) => {
                        return (
                          <Tag
                            color={`var(${
                              x.color ?? "--dashboard-color-orange"
                            })`}
                            style={{ borderRadius: 8 }}
                          >{`${x.defectStatus} : ${x.numberOfDefects}`}</Tag>
                        );
                      })}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TripSummaryPopup;

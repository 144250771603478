import { ReactNode } from "react";
import { Layout } from "antd";

import { ReactComponent as LogOutIcon } from "../../resources/icons/logout.svg";
import { ReactComponent as RefreshIcon } from "../../resources/icons/refresh.svg";
import { ReactComponent as Logo } from "../../logo.svg";
import "./SiteLayout.scss";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "../../services/authService";

const { Header, Content } = Layout;
const NoStyleLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const SiteLayout = ({
  children,
}: {
  children: ReactNode;
  showBack?: boolean;
}) => {
  const history = useHistory();
  return (
    <Layout>
      <ToastContainer />
      <Header className="header">
        <NoStyleLink to="/">
          <div className="header-text">
            <Logo className="logo"></Logo> SmartVision
          </div>
        </NoStyleLink>
        <div className="top-menu">
          <p className="action-text">My Estates</p>
          <RefreshIcon
            onClick={() => {
              history.push("/");
            }}
            className="action-icons"
          />
          <LogOutIcon
            onClick={() => {
              AuthService.signout();
              history.push("/login");
            }}
            className="action-icons"
          />
        </div>
      </Header>
      <Layout>
        <Content className="main-content">{children}</Content>
      </Layout>
      {/* <Footer style={{marginTop:50}}>footer</Footer> */}
    </Layout>
  );
};

export default SiteLayout;

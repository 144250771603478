import { Row, Col, Tabs, Typography, Empty, Tag, Select } from "antd";
import Title from "antd/lib/typography/Title";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
//import ReactPlayer from "react-player";
import { useState, useEffect, ReactNode } from "react";
// import {
// 	formatDateTime,
// 	// millisToMinutesAndSeconds,
// } from "../../helpers/dateHelper";
import CustomCard from "../../components/CustomCard/CustomCard";
import "./InspectionSummaryMapView.scss";
import CustomMap from "../../components/CustomMap/CustomMap";
import { InspectionTypes } from "../../types/InspectionTypesEnum";
import { InspectionSummaryMapUrlParams } from "../../types/InspectionSummaryMapUrlParams";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../components/Notification/Notification";
import api from "../../config/axiosConfig";
import endpointConfig from "../../config/endpointConfig";
import { CloudResponse } from "../../types/CloudResponse";
import { DefectSummaryResponse } from "../../types/DefectSummaryResponse";
import { DefectsByCategoryResponse } from "../../types/DefectsByCategoryResponse";
import { EstateInfoResponse } from "../../types/EstateDetailsResponse";
import { formatDate } from "../../helpers/dateHelper";
import { DefectStatusResponse } from "../../types/DefectStatusResponse";
import DefectDetail from "../../components/DefectDetail/DefectDetail";
import {
	transformRoadDefect,
	transformTreeDefect,
} from "../../helpers/dtoTransformer";
import { RoadDefectDetailResponse } from "../../types/RoadDefectDetailResponse";
import { TreeDefectDetailResponse } from "../../types/TreeDefectDetailResponse";
import { logUnhandledErrors } from "../../helpers/errorLogHelper";
import { DefectTypes } from "../../types/DefectTypesEnum";
import { LatLngBounds } from "leaflet";
import { TripDetail } from "../../types/TripDetail";

const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;
const WhiteBGCard = styled(CustomCard)`
	background: var(--bg);
	height: 120px;
`;

const InspectionSummaryMapView = () => {
	const history = useHistory();
	const { estateId, defectSegment, defectTypeId, defectId } =
		useParams<InspectionSummaryMapUrlParams>();
	const [estateInfo, setEstateInfo] = useState<EstateInfoResponse>();
	const [isDetailsLoading, setDetailsLoading] = useState<boolean>(false);
	const [activatedTab, setActivatedTab] = useState("1");
	const [defectsSummaryByCategory, setDefectsSummaryByCategory] =
		useState<DefectSummaryResponse[]>();
	const [defectsPerCategory, setDefectsPerCategory] =
		useState<DefectsByCategoryResponse>();
	const [roadDefectDetails, setRoadDefectDetails] =
		useState<RoadDefectDetailResponse>();
	const [treeDefectDetails, setTreeDefectDetails] =
		useState<TreeDefectDetailResponse>();
	// const [mapTileView, setMapTileView] =
	// 	useState<GeoJSON.FeatureCollection<any>>();
	const [defectStatus, setDefectStatus] = useState<DefectStatusResponse[]>();
	const [formDisabled, setFormStatus] = useState(true);
	const [selectedDefectId, setSelectedDefectId] = useState("");
	const [mapBounds, setMapBounds] = useState<LatLngBounds>();
	const [tripDetails, setTripDetails] = useState<TripDetail[]>();
	const [tripId, setTripId] = useState<number>(-1);

	useEffect(() => {
		if (estateId) {
			setDetailsLoading(true);
			api
				.get(
					`${endpointConfig.GET_TRIP_DETAILS}`.replace("estate-id", estateId)
				)
				.then((res) => {
					const response: CloudResponse<TripDetail[]> = res.data;
					if (response.isSuccess) {
						setTripDetails(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				})
				.finally(() => {
					setDetailsLoading(false);
				});
		}
	}, [estateId]);

	useEffect(() => {
		setDefectsPerCategory({
			defectList: [],
			numberOfDefects: 0,
			defectCategory: "",
		});
		setDetailsLoading(true);
		if (estateId) {
			setActivatedTab("1");
			if (defectId) {
				setActivatedTab("2");
				getDefectDetails(defectId);
				setFormStatus(true);
				api
					.get(`${endpointConfig.GET_ALL_DEFECTS_STATUS}`)
					.then((res) => {
						const response: CloudResponse<DefectStatusResponse[]> = res.data;

						if (response.isSuccess) {
							setDefectStatus(response.data);
						} else {
							showErrorNotification(response.msg ? response.msg : undefined);
						}
					})
					.catch((err) => {
						logUnhandledErrors(err);
						showErrorNotification(
							"Error occurred while processing the request."
						);
					});
			}
			api
				.get(
					`${endpointConfig.GET_ALL_DEFECT_SUMMARY_BY_TRIP_ID}`
						.replace("estate-id", estateId!)
						.replace(
							"ne-lat",
							(mapBounds && mapBounds.getNorthEast()?.lat.toString()) ?? "0"
						)
						.replace(
							"ne-lon",
							(mapBounds && mapBounds.getNorthEast()?.lng.toString()) ?? "0"
						)
						.replace(
							"sw-lat",
							(mapBounds && mapBounds.getSouthWest()?.lat.toString()) ?? "0"
						)
						.replace(
							"sw-lon",
							(mapBounds && mapBounds.getSouthWest()?.lng.toString()) ?? "0"
						)
						.replace("trip-id", tripId !== -1 ? tripId.toString() : "")
				)
				.then((res) => {
					const response: CloudResponse<DefectSummaryResponse[]> = res.data;

					if (response.isSuccess) {
						setDefectsSummaryByCategory(response.data);
					} else {
						// showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
			api
				.get(
					`${endpointConfig.GET_ESTATE_DETAILS}`.replace("estate-id", estateId)
				)
				.then((res) => {
					const response: CloudResponse<EstateInfoResponse> = res.data;

					if (response.isSuccess) {
						setEstateInfo(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
			if (defectSegment) {
				api
					.get(
						`${endpointConfig.GET_ALL_DEFECTS_BY_CATEGORY_AND_TRIP_ID}`
							.replace("estate-id", estateId!)
							.replace("defect-type-id", defectTypeId ? defectTypeId : "-1")
							.replace(
								"inspection-type",
								defectSegment
									? defectSegment?.toString()
									: InspectionTypes.RoadInspection
							)
							.replace(
								"ne-lat",
								(mapBounds && mapBounds.getNorthEast()?.lat.toString()) ?? "0"
							)
							.replace(
								"ne-lon",
								(mapBounds && mapBounds.getNorthEast()?.lng.toString()) ?? "0"
							)
							.replace(
								"sw-lat",
								(mapBounds && mapBounds.getSouthWest()?.lat.toString()) ?? "0"
							)
							.replace(
								"sw-lon",
								(mapBounds && mapBounds.getSouthWest()?.lng.toString()) ?? "0"
							)
							.replace("trip-id", tripId.toString())
					)
					.then((res) => {
						const response: CloudResponse<DefectsByCategoryResponse> = res.data;

						if (response.isSuccess) {
							setDefectsPerCategory(response.data);
						} else {
							showErrorNotification(response.msg ? response.msg : undefined);
						}
					})
					.catch((err) => {
						logUnhandledErrors(err);
						showErrorNotification(
							"Error occurred while processing the request."
						);
					})
					.finally(() => {
						setDetailsLoading(false);
					});
			} else {
				api
					.get(
						`${endpointConfig.GET_ALL_DEFECTS_BY_CATEGORY_AND_TRIP_ID}`
							.replace("estate-id", estateId!)
							.replace("defect-type-id", defectTypeId ? defectTypeId : "-1")
							.replace(
								"inspection-type",
								defectSegment
									? defectSegment?.toString()
									: InspectionTypes.RoadInspection
							)
							.replace(
								"ne-lat",
								(mapBounds && mapBounds.getNorthEast()?.lat.toString()) ?? "0"
							)
							.replace(
								"ne-lon",
								(mapBounds && mapBounds.getNorthEast()?.lng.toString()) ?? "0"
							)
							.replace(
								"sw-lat",
								(mapBounds && mapBounds.getSouthWest()?.lat.toString()) ?? "0"
							)
							.replace(
								"sw-lon",
								(mapBounds && mapBounds.getSouthWest()?.lng.toString()) ?? "0"
							)
							.replace("trip-id", tripId.toString())
					)
					.then((res) => {
						const response: CloudResponse<DefectsByCategoryResponse> = res.data;

						if (response.isSuccess) {
							setDefectsPerCategory(response.data);
						} else {
							showErrorNotification(response.msg ? response.msg : undefined);
						}
					})
					.catch((err) => {
						logUnhandledErrors(err);
						showErrorNotification(
							"Error occurred while processing the request."
						);
					})
					.finally(() => {
						setDetailsLoading(false);
					});
			}
		}
	}, [estateId, defectSegment, defectTypeId, defectId, tripId]);

	const tabChange = (tabId: string) => {
		if (tabId === "1" && defectId) {
			history.push(
				"/inspections-summary-map/" +
					estateId +
					"/" +
					defectSegment +
					"/" +
					defectTypeId
			);
			// setActivatedTab(tabId);
			// window.location.reload();
		}
	};
	const getDefectDetails = (defectId: string) => {
		api
			.get(
				`${
					InspectionTypes.RoadInspection === defectSegment
						? endpointConfig.GET_ROAD_DEFECT_DETAILS
						: endpointConfig.GET_TREE_DEFECT_DETAILS
				}`.replace("defect-id", defectId)
			)
			.then((res) => {
				if (InspectionTypes.RoadInspection === defectSegment) {
					const response: CloudResponse<RoadDefectDetailResponse> = res.data;
					if (response.isSuccess) {
						setRoadDefectDetails(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				} else {
					const response: CloudResponse<TreeDefectDetailResponse> = res.data;
					if (response.isSuccess) {
						setTreeDefectDetails(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification("Error occurred while processing the request.");
			});
	};
	const updateDefectDetails = (
		defectStatusId: number,
		observationNotes: string,
		comments: string
	): void => {
		if (roadDefectDetails?.defectId || treeDefectDetails?.defectId) {
			const defectId =
				InspectionTypes.RoadInspection === defectSegment
					? roadDefectDetails?.defectId
					: treeDefectDetails?.defectId;
			api
				.put(
					`${
						InspectionTypes.RoadInspection === defectSegment
							? endpointConfig.UPDATE_ROAD_DEFECT_STATUS
							: endpointConfig.UPDATE_TREE_DEFECT_STATUS
					}`,
					{
						defectId: defectId,
						statusId: defectStatusId,
						comment: comments,
						observationNotes: observationNotes,
					}
				)
				.then((res) => {
					const response: CloudResponse<object> = res.data;
					if (response.isSuccess) {
						getDefectDetails(defectId!);
						setFormStatus(true);
						showSuccessNotification("successfully updated.");
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
		}
	};

	useEffect(() => {
		// window.location.reload();
	}, [defectId]);

	return (
		<div className="inspection-summary-map-view">
			{estateId ? (
				<Row gutter={[20, 20]}>
					<Col lg={17} md={17} sm={12}>
						<div className="map-view-summary-map-section">
							<CustomMap
								defectList={
									defectsPerCategory?.defectList
										? defectsPerCategory?.defectList
										: []
								}
								estateInfo={estateInfo}
								selectedDefectId={selectedDefectId}
								mapBounds={mapBounds}
								setMapBounds={setMapBounds}
							></CustomMap>
						</div>
					</Col>
					<Col lg={7} md={7} sm={12}>
						<CustomCard className="ant-summary-card" loading={isDetailsLoading}>
							<Row gutter={[20, 20]}>
								{defectTypeId && (
									<Col span={4}>
										<Link to={`/inspections-summary-map/${estateId}`}>
											<LeftOutlined style={{ fontSize: 40 }} />
										</Link>
									</Col>
								)}
								<Col span={20}>
									{defectId ? (
										<div></div>
									) : (
										<div>
											<Title className="map-summary-title" level={3}>
												{defectTypeId
													? defectsPerCategory?.defectCategory
													: estateInfo?.estateName}
											</Title>
											<p>Some statistics for you</p>
											<Select
												className="trip-selector"
												bordered={false}
												value={tripId}
												onChange={(value) => setTripId(value)}
												dropdownMatchSelectWidth={false}
											>
												<Option key={-1} value={-1}>
													All
												</Option>
												{tripDetails?.map((x): ReactNode => {
													return (
														<Option key={x.tripId} value={x.tripId}>
															{x.tripId +
																": " +
																x.tripDate +
																",  " +
																x.startTime.substring(0, 5)}
														</Option>
													);
												})}
											</Select>
										</div>
									)}
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Tabs
										defaultActiveKey={activatedTab}
										activeKey={activatedTab}
										centered={true}
										onChange={tabChange}
									>
										<TabPane
											tab={<span className="">Overview</span>}
											key="1"
											className="summary-tab"
										>
											{defectTypeId ? (
												<Row>
													{defectSegment === InspectionTypes.RoadInspection ? (
														<Col span={23}>
															<div className="player-wrapper">
																{/* <ReactPlayer
                                  width="100%"
                                  height="auto"
                                  url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                /> */}
															</div>
														</Col>
													) : (
														<></>
													)}
													<Col span={24}>
														<div className="defect-type-list-header">
															<p className="sub-text">
																{defectsPerCategory?.defectCategory}
															</p>
															<p className="sub-text">
																<Text type="secondary">
																	{defectsPerCategory?.numberOfDefects}
																	&nbsp; detected
																</Text>
																&nbsp; .&nbsp;
																<Text type="secondary"> Tap to view more</Text>
															</p>
														</div>
													</Col>
													<Col span={24} className="defect-list">
														{defectsPerCategory?.defectList ? (
															defectsPerCategory?.defectList.map(
																(defect, i) => {
																	if (
																		defect.defectTypeId.toString() ===
																		defectTypeId
																	) {
																		return (
																			<Row className="defect-list-row">
																				<Col span={18}>
																					<Link
																						onMouseOver={() => {
																							setSelectedDefectId(
																								`${defect.defectTypeKey}_${defect.defectId}`
																							);
																						}}
																						to={
																							"/inspections-summary-map/" +
																							estateId +
																							"/" +
																							defectSegment +
																							"/" +
																							defectTypeId +
																							"/" +
																							defect.defectId
																						}
																					>
																						<p className="sub-text">
																							{defect.defectName}
																						</p>
																					</Link>
																				</Col>
																				<Col span={6}>
																					<Tag color="geekblue">
																						{" "}
																						{defect.defectStatus}
																					</Tag>
																				</Col>
																				<Col span={24}>
																					<Row gutter={[5, 5]}>
																						<Col className="sub-text-gray">
																							{formatDate(defect.defectDate)}
																						</Col>
																						<Col></Col>
																						<Col className="sub-text-gray">
																							{(defect.defectTypeKey ===
																								DefectTypes.Pothole ||
																								defect.defectTypeKey ===
																									DefectTypes.Kerbs) &&
																								(defect.area ? (
																									<>
																										<small>Area : </small>
																										<Text>
																											{defect.area} + m
																											<sup>2</sup>
																										</Text>
																									</>
																								) : (
																									<p className="text-x-small">
																										Area : ...
																									</p>
																								))}

																							{defect.defectTypeKey ===
																								DefectTypes.RoadMarking &&
																								(defect.fadedScore ? (
																									<>
																										<small>
																											Faded Score :{" "}
																										</small>
																										<Text>
																											{defect.fadedScore.toFixed(
																												2
																											)}
																										</Text>
																									</>
																								) : (
																									<p className="text-x-small">
																										Faded Score : ...
																									</p>
																								))}
																						</Col>
																						<Col className="sub-text-gray">
																							{defect.defectTypeKey ===
																								DefectTypes.Pothole &&
																								(defect.depth ? (
																									<>
																										<small>Depth : </small>
																										<Text>
																											{defect.depth} + "m"
																										</Text>
																									</>
																								) : (
																									<p className="text-x-small">
																										Depth : ...
																									</p>
																								))}
																						</Col>
																					</Row>
																				</Col>
																			</Row>
																		);
																	} else {
																		return null;
																	}
																}
															)
														) : (
															<Empty description="No defects found."></Empty>
														)}
													</Col>
												</Row>
											) : (
												<Row gutter={[15, 15]}>
													{defectsSummaryByCategory?.map((defect, i) => {
														return (
															<Col span={12}>
																<Link
																	to={`/inspections-summary-map/${estateId}/${defect.typeOfInspection}/${defect.defectTypeId}`}
																>
																	<WhiteBGCard key={i}>
																		<div className="summary-card">
																			<Title level={1}>
																				{defect.numberOfDefects}
																			</Title>
																			<p>{defect.defectType}</p>
																		</div>
																	</WhiteBGCard>
																</Link>
															</Col>
														);
													})}
												</Row>
											)}
										</TabPane>
										<TabPane
											disabled={!defectId}
											tab={<span>Defect</span>}
											key="2"
										>
											{(InspectionTypes.RoadInspection === defectSegment &&
												roadDefectDetails) ||
											(InspectionTypes.TreeInspection === defectSegment &&
												treeDefectDetails) ? (
												<DefectDetail
													defectDetails={
														InspectionTypes.RoadInspection === defectSegment
															? transformRoadDefect(
																	roadDefectDetails!,
																	setFormStatus,
																	updateDefectDetails,
																	formDisabled,
																	defectStatus
															  )
															: transformTreeDefect(
																	treeDefectDetails!,
																	setFormStatus,
																	updateDefectDetails,
																	formDisabled,
																	defectStatus
															  )
													}
												></DefectDetail>
											) : (
												<Empty
													className="empty-msg"
													description="Click On Row to View Defect"
												></Empty>
											)}
										</TabPane>
									</Tabs>
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			) : (
				<Empty description="Invalid estate selection"></Empty>
			)}
		</div>
	);
};
export default InspectionSummaryMapView;

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";
import SiteLayout from "./components/SiteLayout/SiteLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import RoadInspectionList from "./pages/RoadInspectionList/RoadInspectionList";
import InspectionSummaryMapView from "./pages/InspectionSummaryMapView/InspectionSummaryMapView";
import TreeInspectionList from "./pages/TreeInspectionList/TreeInspectionList";
import Login from "./pages/Login/Login";
import { PrivateRoute } from "./pages/PrivateRoute/PrivateRoute";
import TripSummaryList from "./pages/TripSummaryList/TripSummaryList";
import EstateLevelView from "./pages/EstateLevelView/EstateLevelView";
// import {
//   configAuthHeaders,
//   configRefreshToken,
// } from "./services/axiosConfigService";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/login">
					<Login />
				</Route>
				<PrivateRoute exact path="/">
					<SiteLayout>
						<Dashboard />
					</SiteLayout>
				</PrivateRoute>
				<PrivateRoute path="/road-inspections-list/:estateId">
					<SiteLayout>
						<RoadInspectionList />
					</SiteLayout>
				</PrivateRoute>
				<PrivateRoute path="/tree-inspections-list/:estateId">
					<SiteLayout>
						<TreeInspectionList />
					</SiteLayout>
				</PrivateRoute>
				<PrivateRoute path="/inspections-summary-map/:estateId/:defectSegment?/:defectTypeId?/:defectId?">
					<SiteLayout>
						<InspectionSummaryMapView />
					</SiteLayout>
				</PrivateRoute>
				<PrivateRoute path="/trip-summary-list/:estateId">
					<SiteLayout>
						<TripSummaryList />
					</SiteLayout>
				</PrivateRoute>
				<PrivateRoute path="/estate-level-view/:estateId">
					<SiteLayout>
						<EstateLevelView />
					</SiteLayout>
				</PrivateRoute>
			</Switch>
		</Router>
	);
}

export default App;

import { Form, FormInstance, Input, Select } from "antd";
import { ReactNode } from "react";
import TextArea from "antd/lib/input/TextArea";

import "./DefectDetailForm.scss";
import { DefectStatusResponse } from "../../types/DefectStatusResponse";

const { Option } = Select;

const DefectDetailForm = ({
  defectStatus,
  observationNotes,
  comments,
  statusId,
  formDisabled,
  form,
}: {
  defectStatus: DefectStatusResponse[];
  observationNotes: string;
  comments: string;
  statusId: number;
  formDisabled: boolean;
  form: FormInstance<any>;
}) => {
  form.setFieldsValue({
    observationNotes: observationNotes,
    comments: comments,
    statusId: statusId,
  });
  return (
    <div className="defect-form">
      <Form size="small" layout="vertical" form={form}>
        <Form.Item name="observationNotes" label="Observation Notes :">
          <Input
            disabled={formDisabled}
            placeholder="Enter Observation Notes"
          />
        </Form.Item>
        <Form.Item
          name="statusId"
          className="inline-form-item"
          label="Status :"
        >
          <Select disabled={formDisabled}>
            {defectStatus?.map((x): ReactNode => {
              return (
                <Option key={x.defectStatusId} value={x.defectStatusId}>
                  {x.defectStatus}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="comments" label="Comments :">
          <TextArea disabled={formDisabled} placeholder="Enter Comments" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DefectDetailForm;

import { ReactNode, useEffect, useState } from 'react';
import { Col, Row, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Link, useParams } from 'react-router-dom';
import CustomTable from '../../components/CustomTable/CustomTable';
import CustomCard from '../../components/CustomCard/CustomCard';
import './TripSummaryList.scss';
import endpointConfig from '../../config/endpointConfig';
import { CloudResponse } from '../../types/CloudResponse';
import api from '../../config/axiosConfig';
import {
	TableDataBase,
	TableDataResponseBase,
} from '../../types/TableDataBase';
import { showErrorNotification } from '../../components/Notification/Notification';
import { logUnhandledErrors } from '../../helpers/errorLogHelper';
import { TripSummaryListData } from '../../types/TripSummaryListData';
import {
	verboseDateTime
	// millisToMinutesAndSeconds,
} from '../../helpers/dateHelper';
import { StatusResponse } from '../../types/StatusResponse';
import ReportLoadingModal from '../../components/ReportLoadingModal/ReportLoadingModal';
import { EstateInfoResponse } from '../../types/EstateDetailsResponse';
import { ReportGenerationDefectSummary } from '../../types/ReportGenerationDefectSummary';
import { TripIdResponse } from '../../types/TripIdResponse';
const { Option } = Select;

const TripSummaryList = () => {
	// const [estates, setEstates] = useState<EstateResponse[]>([]);
	const [estate, setEstate] = useState<EstateInfoResponse>();
	const { estateId } = useParams<{ estateId: string }>();
	const [statuses, setStatuses] = useState<StatusResponse[]>([]);
	const [statusIdDropDown, setStatusIdDropDown] = useState('2');
	const [tripIds, setTripIds] = useState<TripIdResponse[]>([]);
	const [tripIdDropdown, setTripIdDropdown] = useState('');
	const [tripSummaryList, setTripSummaryList] =
		useState<TableDataBase<TripSummaryListData>>();
	const [defectSummaryList, setDefectSummaryList] =
		useState<TableDataBase<ReportGenerationDefectSummary>>();
	const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
	const [tripSummaryGridColumn] = useState<any[]>([
		{
			title: 'Start Date / Time',
			dataIndex: 'startTime',
			key: 'startTime',
			sorter: false,
			responsive: ['md'],
			render: (date: string) => {
				return verboseDateTime(date);
			},
		},
		{
			title: 'End Date / Time',
			dataIndex: 'endTime',
			key: 'endTime',
			sorter: false,
			responsive: ['md'],
			render: (date: string) => {
				return verboseDateTime(date);
			},
		},
		{
			title: 'Duration (mins)',
			dataIndex: 'duration',
			key: 'duration',
			sorter: false,
			responsive: ['md'],
			render: (hours: number, row: any) => {
				return Math.round(hours * 60);
			},
		},
	]);

	const [defectSummaryGridColumn] = useState<any[]>([
		{
			title: 'Defect Type',
			dataIndex: 'defectType',
			key: 'defectType',
			sorter: false,
			responsive: ['md'],
		},
		{
			title: 'Defect Count',
			dataIndex: 'defectCount',
			key: 'defectCount',
			sorter: false,
			responsive: ['md'],
		},
		{
			title: 'Generate Report',
			dataIndex: 'defectTypeId',
			key: 'defectTypeId',
			sorter: false,
			responsive: ['md'],
			render: (defectTypeId: string, row: ReportGenerationDefectSummary) => {
				return (
					<Link
						className="link"
						to="#"
						title="Click to download the report"
						onClick={(e) => {
							e.preventDefault();
							if (row.defectCount < 1) {
								showErrorNotification(
									'A report cannot be generated for this defect type as there are no defects of this type.'
								);
							} else {
								const endpoint = endpointConfig.GET_DEFECT_REPORT.replace(
									'trip-id',
									row.tripId
								).replace('defect-type-id', defectTypeId);
								requestReport(endpoint);
							}
						}}
					>
						Download Report
					</Link>
				);
			},
		},
	]);

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const requestReport = (endpoint: string) => {
		setShowLoadingModal(true);

		api.get(endpoint).then((response) => {
			if (response.status === 200) {
				openInNewTab(response.data);
			} else {
				showErrorNotification(
					'There are no verified defects available for the selected trip and defect type.'
				);
			}
			setShowLoadingModal(false);
		});
	};

	useEffect(() => {
		// only fetch table data if tripIdDropdown is not empty
		if (tripIdDropdown) {
			fetchTableData();
		}
	}, [tripIdDropdown, statusIdDropDown]);

	useEffect(() => {
		api
			.get(endpointConfig.GET_STATUSES)
			.then((res) => {
				const response: CloudResponse<StatusResponse[]> = res.data;
				if (response.isSuccess) {
					setStatuses(response.data);
				} else {
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification('Error occurred while processing the request.');
			});
	}, []);

	useEffect(() => {
		api
			.get(
				`${endpointConfig.GET_ESTATE_DETAILS.replace('estate-id', estateId)}`
			)
			.then((res) => {
				const response: CloudResponse<EstateInfoResponse> = res.data;
				if (response.isSuccess) {
					setEstate(response.data);
				} else {
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification('Error occurred while processing the request.');
			});
		// populate the trip-id dropdown
		api
			.get(`${endpointConfig.GET_USER_TRIPS.replace('estate-id', estateId)}`)
			.then((res) => {
				const response: CloudResponse<TripIdResponse[]> = res.data;
				if (response.isSuccess) {
					setTripIds(response.data);
				} else {
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification('Error occurred while processing the request.');
			});
	}, [estateId]);

	const fetchTableData = () => {
		setTripSummaryList((prevState): any => {
			return { ...prevState, loading: true };
		});
		setDefectSummaryList((prevState): any => {
			return { ...prevState, loading: true };
		});

		console.log(
			endpointConfig.GET_REPORT_GENERATION_TRIP_SUMMARY.replace(
				'trip-id',
				tripIdDropdown
			)
		);
		api
			.get(
				endpointConfig.GET_REPORT_GENERATION_TRIP_SUMMARY.replace(
					'trip-id',
					tripIdDropdown
				)
			)
			.then((res) => {
				const response: CloudResponse<TripSummaryListData[]> = res.data;
				if (response.isSuccess) {
					const tableData: TableDataBase<TripSummaryListData> = {
						data: response.data,
						loading: false,
						// mock data since pagination isn't used
						pagination: {
							current: 1,
							total: 1,
							pageSize: 10,
						},
						sortKey: tripSummaryList?.sortKey.toString() ?? '',
						sortOrder: tripSummaryList?.sortOrder.toString() ?? '',
						filter: tripSummaryList?.filter ?? [],
					};
					setTripSummaryList(tableData);
				} else {
					setTripSummaryList({
						data: [],
						loading: false,
						pagination: {
							current: 1,
							total: 0,
							pageSize: tripSummaryList?.pagination.pageSize ?? 10,
						},
						sortKey: tripSummaryList?.sortKey.toString() ?? '',
						sortOrder: tripSummaryList?.sortOrder.toString() ?? '',
						filter: tripSummaryList?.filter ?? [],
					});
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification('Error occurred while processing the request.');
			});
		api
			.get(
				endpointConfig.GET_REPORT_GENERATION_DEFECT_SUMMARY.replace(
					'trip-id',
					tripIdDropdown
				).replace('defect-status-id', statusIdDropDown)
			)
			.then((res) => {
				const response: CloudResponse<ReportGenerationDefectSummary[]> =
					res.data;
				if (response.isSuccess) {
					const tableData: TableDataBase<ReportGenerationDefectSummary> = {
						data: response.data,
						loading: false,
						// mock data since pagination isn't used
						pagination: {
							current: 1,
							total: 1,
							pageSize: 10,
						},
						sortKey: tripSummaryList?.sortKey.toString() ?? '',
						sortOrder: tripSummaryList?.sortOrder.toString() ?? '',
						filter: tripSummaryList?.filter ?? [],
					};
					setDefectSummaryList(tableData);
				} else {
					setDefectSummaryList({
						data: [],
						loading: false,
						pagination: {
							current: 1,
							total: 0,
							pageSize: tripSummaryList?.pagination.pageSize ?? 10,
						},
						sortKey: tripSummaryList?.sortKey.toString() ?? '',
						sortOrder: tripSummaryList?.sortOrder.toString() ?? '',
						filter: tripSummaryList?.filter ?? [],
					});
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification('Error occurred while processing the request.');
			});
	};

	const onChange = (value: string) => {
		console.log(`selected ${value}`);
	};

	const onSearch = (value: string) => {
		console.log('search:', value);
	};
	return (
		<>
			<div className="trip-summary-list" style={{ paddingBottom: '5%' }}>
				<Row gutter={[24, 24]}>
					<Col span={24}>
						<Title level={2}>Report Generation</Title>
						<Title level={4}>{estate?.estateName ?? ''}</Title>
					</Col>
					<Col span={6}>
						Defect status:
						<Select
							className="status-selector"
							bordered={false}
							value={statusIdDropDown}
							disabled={true}
							// onChange={(value) => setStatusIdDropDown(value)
							// }
						>
							{statuses?.map((x): ReactNode => {
								return (
									<Option key={x.statusId} value={x.statusId.toString()}>
										{x.status}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col span={12}>
						Trip Id: &nbsp;
						<Select
							showSearch
							placeholder="Choose a trip id"
							optionFilterProp="children"
							onChange={(value: string) => setTripIdDropdown(value)}
							onSearch={onSearch}
							bordered={false}
							style={{ width: 140 }}
							filterOption={(input, option: any) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={tripIds?.map((x) => {
								return { value: x.tripId, label: x.tripId };
							})}
						/>
						&nbsp;
						{/* <Button type="primary" icon={<SearchOutlined />}>
							Search
						</Button> */}
					</Col>
				</Row>

				<Row gutter={[24, 24]}>
					<Col lg={24} md={24} sm={24} xs={24}>
						<Title level={4} style={{ marginTop: '5%' }}>
							Trip Summary
						</Title>
						<CustomCard>
							<CustomTable
								className="trip-summary-table"
								dataSource={tripSummaryList?.data}
								columns={tripSummaryGridColumn}
								pagination={false}
								loading={tripSummaryList?.loading}
								size="middle"
							/>
						</CustomCard>
					</Col>
				</Row>

				<Row gutter={[24, 24]}>
					<Col lg={24} md={24} sm={24} xs={24}>
						<Title level={4} style={{ marginTop: '5%' }}>
							Defect Summary
						</Title>
						<CustomCard>
							<CustomTable
								className="trip-summary-table"
								dataSource={defectSummaryList?.data}
								columns={defectSummaryGridColumn}
								pagination={false}
								loading={defectSummaryList?.loading}
								size="middle"
							/>
						</CustomCard>
					</Col>
				</Row>
			</div>
			<ReportLoadingModal
				isModalVisible={showLoadingModal}
				setIsModalVisible={setShowLoadingModal}
			></ReportLoadingModal>
		</>
	);
};
export default TripSummaryList;

import { PageHeader, Row } from "antd";
import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { EstateDefectsFixedResponse } from "../../types/EstateDefectsFixedResponse";
import CustomCard from "../CustomCard/CustomCard";

const EstateDefectFixedCard = ({
	EstateDefectsFixedProps,
}: {
	EstateDefectsFixedProps: EstateDefectsFixedResponse[];
}) => {
	const data = EstateDefectsFixedProps;
	const barColors = [
		"#80d4be",
		"#8589f1",
		"#dc8cca",
		"#f3cc82",
		"#6E5773",
		"#D45079",
		"#EA9085",
		"#E9E1CC",
	];
	const chartData = data.map((metric, index) => {
		return {
			name: metric.estateName,
			defectsFixed: metric.totalDefectsFixed,
			fill: barColors[index % barColors.length],
		};
	});

	const card = (
		<CustomCard>
			<PageHeader title="Total Defects Fixed">
				<Row>
					<ResponsiveContainer width="95%" height={300}>
						<BarChart
							data={chartData}
							layout="horizontal"
							margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis type="category" dataKey="name" />
							<YAxis type="number" />
							<Tooltip />
							<Bar
								dataKey="defectsFixed"
								name="Defects Fixed"
								radius={[0, 10, 10, 0]}
							/>
						</BarChart>
					</ResponsiveContainer>
				</Row>
			</PageHeader>
		</CustomCard>
	);

	return card;
};

export default EstateDefectFixedCard;

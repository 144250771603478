import { Col, Divider, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { TableOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import CustomCard from "./CustomCard";
import { ReactComponent as RoadInspectionIcon } from "../../resources/icons/red-car.svg";
import { ReactComponent as TreeInspectionIcon } from "../../resources/icons/tree-inspection.svg";
import "./InspectionSumaryCard.scss";
import { InspectionSummaryResponse } from "../../types/InspectionSummaryResponse";
import { InspectionTypes } from "../../types/InspectionTypesEnum";

// const style = {
//   top: "50%",
//   right: "10px",
//   transform: "translate(0, -50%)",
//   lineHeight: "24px",
// };
const InspectionSumaryCard = ({
	inspectionSummary,
	type,
	estateId,
}: {
	inspectionSummary?: InspectionSummaryResponse;
	type: string;
	estateId: string;
}) => {
	return (
		<div className="inspection-summary ">
			<CustomCard loading={!inspectionSummary}>
				<Row className="inspection-sumary-row">
					<Col md={5} sm={24} xs={24}>
						<div className="inspection-summary-icon-section">
							{type === InspectionTypes.RoadInspection ? (
								<RoadInspectionIcon className="inspection-summary-icon" />
							) : (
								<TreeInspectionIcon className="inspection-summary-icon" />
							)}
						</div>
					</Col>
					<Col md={5} sm={12} xs={24}>
						<div className="inspection-summary-stat-section">
							<Title className="inspection-summary-title" level={5}>
								Total inspections
							</Title>
						</div>
						<div
							className="inspection-summary-stat-section"
							style={{ marginTop: 40 }}
						>
							<Title
								style={{
									display: "inline-flex",
									alignItems: "center",
									margin: 10,
								}}
								level={1}
							>
								{inspectionSummary?.performedInspectionsCount || 0}
							</Title>
						</div>
					</Col>
					<Col md={1} sm={0} xs={0}>
						<Divider className="inspection-summary-devider" type="vertical" />
					</Col>
					<Col md={5} sm={12} xs={24}>
						<div className="inspection-summary-stat-section">
							<Title className="inspection-summary-title" level={5}>
								Avg Trip Duration
							</Title>
						</div>
						<div
							className="inspection-summary-stat-section"
							style={{ marginTop: 40 }}
						>
							<Title
								style={{ display: "inline-flex", alignItems: "center" }}
								level={1}
							>
								{Math.round(inspectionSummary?.avgTripDuration! * 60) || 0}
								<small style={{ margin: 10 }}>mins</small>
							</Title>
						</div>
					</Col>
					<Col md={1} sm={0} xs={0}>
						<Divider className="inspection-summary-devider" type="vertical" />
					</Col>
					<Col md={6} sm={12} xs={24}>
						<div className="inspection-summary-stat-section">
							{type === InspectionTypes?.RoadInspection ? (
								<Title className="inspection-summary-title" level={5}>
									Roads Defects :
									<Link to={`/road-inspections-list/${estateId}`}>
										<TableOutlined className="action-icon" />
									</Link>
								</Title>
							) : (
								<Title className="inspection-summary-title" level={5}>
									Trees :
									<Link to={`/tree-inspections-list/${estateId}`}>
										<TableOutlined className="action-icon" />
									</Link>
								</Title>
							)}
						</div>
						<div
							style={{ marginTop: 40 }}
							className="inspection-summary-stat-section"
						>
							<Title
								style={{ display: "inline-flex", alignItems: "center" }}
								level={1}
							>
								<div style={{ color: "#FF7F21" }}>
									{!inspectionSummary
										? 0
										: inspectionSummary.closedDefectsCount}
								</div>
								<small style={{ margin: 10 }}>of</small>
								{!inspectionSummary ? 0 : inspectionSummary.allDefectsCount}
							</Title>
						</div>
						{type === InspectionTypes.RoadInspection ? (
							<p className="inspection-summary-stat-section">Defects Fixed</p>
						) : (
							<p className="inspection-summary-stat-section">Trees Corrected</p>
						)}
					</Col>
				</Row>
			</CustomCard>
		</div>
	);
};

export default InspectionSumaryCard;

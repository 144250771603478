import { Modal, Spin } from "antd";
import CustomCard from "../CustomCard/CustomCard";

const ReportLoadingModal = ({
	isModalVisible,
	setIsModalVisible,
}: {
	isModalVisible: boolean;
	setIsModalVisible: (data: boolean) => void;
}) => {
	return (
		<div className="trip-summary-popup">
			<Modal
				title={`Report is being generated now`}
				visible={isModalVisible}
				footer={null}
				closable={true}
				destroyOnClose={true}
				onCancel={() => setIsModalVisible(false)}
			>
				<div className="popup-content">
					<CustomCard>
						<div className="flex-container">
							<Spin size="large" />
						</div>
					</CustomCard>
				</div>
			</Modal>
		</div>
	);
};

export default ReportLoadingModal;

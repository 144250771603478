import axios from "axios";
import { useHistory } from "react-router-dom";
import { REACT_TOKEN_AUTH, BASE_URL_CLOUD_API } from "../constants/constants";
import { AuthService } from "../services/authService";
require("dotenv").config();

const api = axios.create({
  baseURL: BASE_URL_CLOUD_API,
});

api.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token =
        localStorage &&
        localStorage.getItem(REACT_TOKEN_AUTH) &&
        JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH) ?? "").accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    console.log(response.status);

    if (response.status === 403) {
      ///refresh token logic
      AuthService.signout();
      const history = useHistory();
      history.push("/login");
    } else if (response.status === 400 || response.status === 401) {
      AuthService.signout();
      const history = useHistory();
      history.push("/login");
    }
  }
);

export default api;

import { Col, Empty, Row, TablePaginationConfig, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import Title from "antd/lib/typography/Title";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import CustomCard from "../../components/CustomCard/CustomCard";
import CustomTable from "../../components/CustomTable/CustomTable";
import DefectDetail from "../../components/DefectDetail/DefectDetail";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../components/Notification/Notification";
import api from "../../config/axiosConfig";
import endpointConfig from "../../config/endpointConfig";
import { formatDate } from "../../helpers/dateHelper";
import {
  filterObjectToUrlParam,
  transformTreeDefect,
} from "../../helpers/dtoTransformer";
import { logUnhandledErrors } from "../../helpers/errorLogHelper";
import { CloudResponse } from "../../types/CloudResponse";
import { DefectStatusResponse } from "../../types/DefectStatusResponse";
import { EstateInfoResponse } from "../../types/EstateDetailsResponse";
import {
  TableDataBase,
  TableDataResponseBase,
} from "../../types/TableDataBase";
import { TreeDefectDetailResponse } from "../../types/TreeDefectDetailResponse";
import { TreeInspectionDefectData } from "../../types/TreeInspectionDefectData";
import "./TreeInspectionList.scss";

const { Text } = Typography;

let columns: any = [
  {
    title: "Defect Id",
    dataIndex: "defectId",
    key: "defectId",
    sorter: false,
    responsive: ["md"],
  },
  {
    title: "Date",
    dataIndex: "defectDate",
    key: "defectDate",
    sorter: true,
    render: (date: string) => {
      return formatDate(date);
    },
    responsive: ["sm"],
  },
  {
    title: "Failure Parts",
    dataIndex: "failurePart",
    key: "failurePart",
    sorter: true,
  },
  {
    title: "Risk",
    dataIndex: "riskRatingh",
    key: "riskRatingh",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "defectStatus",
    key: "defectStatus",
    sorter: true,
    filter: [],
  },
  {
    title: "Inspector Name",
    dataIndex: "inspectorName",
    key: "inspectorName",
    sorter: true,
    responsive: ["md"],
  },
];

const TreeInspectionList = () => {
  const { estateId } = useParams<{ estateId: string }>();
  const [estateInfo, setEstateInfo] = useState<EstateInfoResponse>();
  const [isDetailsLoading, setDetailsLoading] = useState<boolean>(false);
  const [defectStatus, setDefectStatus] = useState<DefectStatusResponse[]>();
  //const [defectTypes, setDefectTypes] = useState<DefectTypeResponse[]>();

  const [defectDetails, setDefectDetails] =
    useState<TreeDefectDetailResponse>();
  const [formDisabled, setFormStatus] = useState(true);
  const [treeInspectionDefectList, setTreeInspectionDefectList] =
    useState<TableDataBase<TreeInspectionDefectData>>();
  useEffect(() => {
    fetchTableData();
    if (estateId) {
      api
        .get(
          `${endpointConfig.GET_ESTATE_DETAILS}`.replace("estate-id", estateId)
        )
        .then((res) => {
          const response: CloudResponse<EstateInfoResponse> = res.data;
          if (response.isSuccess) {
            setEstateInfo(response.data);
          } else {
            showErrorNotification(response.msg ? response.msg : undefined);
          }
        })
        .catch((err) => {
          logUnhandledErrors(err);
          showErrorNotification("Error occurred while processing the request.");
        });

      api
        .get(`${endpointConfig.GET_ALL_DEFECTS_STATUS}`)
        .then((res) => {
          const response: CloudResponse<DefectStatusResponse[]> = res.data;
          if (response.isSuccess) {
            setDefectStatus(response.data);
          } else {
            showErrorNotification(response.msg ? response.msg : undefined);
          }
        })
        .catch((err) => {
          logUnhandledErrors(err);
          showErrorNotification("Error occurred while processing the request.");
        });
    }
  }, [estateId]);
  useEffect(() => {
    if (defectStatus) {
      columns = columns.map((x: { key: string }) =>
        x.key === "defectStatus"
          ? {
              ...x,
              filters: defectStatus.map((x) => ({
                text: x.defectStatus,
                value: x.defectStatusId,
              })),
            }
          : x
      );
    }
  }, [defectStatus]);

  const fetchTableData = () => {
    api
      .get(
        `${endpointConfig.GET_TREE_INSPECTION_DEFECT_DATA}`
          .replace("estate-id", estateId)
          .replace(
            "page-number",
            treeInspectionDefectList?.pagination.current.toString() ?? "1"
          )
          .replace(
            "page-size",
            treeInspectionDefectList?.pagination.pageSize.toString() ?? "10"
          )
          .replace("sort-key", treeInspectionDefectList?.sortKey ?? "")
          .replace(
            "sort-order",
            treeInspectionDefectList?.sortOrder.toString() ?? ""
          )
          .replace(
            "data-filter",
            filterObjectToUrlParam(treeInspectionDefectList?.filter ?? [])
          )
      )
      .then((res) => {
        const response: CloudResponse<
          TableDataResponseBase<TreeInspectionDefectData>
        > = res.data;
        if (response.isSuccess) {
          const tableData: TableDataBase<TreeInspectionDefectData> = {
            data: response.data.pagedData,
            loading: false,
            pagination: {
              current: parseInt(response.data.currentPage),
              total: response.data.totalRecords,
              pageSize: treeInspectionDefectList
                ? treeInspectionDefectList.pagination.pageSize
                : 10,
            },
            sortKey: treeInspectionDefectList
              ? treeInspectionDefectList.sortKey.toString()
              : "",
            sortOrder: treeInspectionDefectList
              ? treeInspectionDefectList.sortOrder.toString()
              : "",
            filter: treeInspectionDefectList?.filter ?? [],
          };
          setTreeInspectionDefectList(tableData);
        } else {
          setTreeInspectionDefectList({
            data: [],
            loading: false,
            pagination: {
              current: 1,
              total: 0,
              pageSize: treeInspectionDefectList?.pagination.pageSize ?? 10,
            },
            sortKey: treeInspectionDefectList?.sortKey.toString() ?? "",
            sortOrder: treeInspectionDefectList?.sortOrder.toString() ?? "",
            filter: treeInspectionDefectList?.filter ?? [],
          });
          showErrorNotification(response.msg ? response.msg : undefined);
        }
      })
      .catch((err) => {
        logUnhandledErrors(err);
        showErrorNotification("Error occurred while processing the request.");
      });
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<object>,
    filter: Record<string, FilterValue | null>
  ) => {
    if (treeInspectionDefectList) {
      treeInspectionDefectList.loading = true;
      if (sorter?.order) {
        treeInspectionDefectList.sortKey = sorter.field?.toString() ?? "";
        treeInspectionDefectList.sortOrder =
          sorter.order === "ascend" ? "asc" : "desc";
      } else {
        treeInspectionDefectList.sortKey = "";
      }
      if (filter) {
        treeInspectionDefectList.filter = [];
        for (let key in Object.keys(filter)) {
          const filterValues: FilterValue | null = Object.values(filter)[key];
          if (filterValues) {
            treeInspectionDefectList.filter.push({
              key: Object.keys(filter)[key],
              values: filterValues,
            });
          }
        }
      }
      treeInspectionDefectList.pagination = {
        current: pagination.current ?? 1,
        pageSize: pagination.pageSize ?? 10,
        total: pagination.total ?? 10,
      };

      setTreeInspectionDefectList(treeInspectionDefectList);
      fetchTableData();
    }
  };
  const getDefectDetails = (defectId: string) => {
    if (defectId) {
      setFormStatus(true);
      setDetailsLoading(true);
      api
        .get(
          `${endpointConfig.GET_TREE_DEFECT_DETAILS}`.replace(
            "defect-id",
            defectId
          )
        )
        .then((res) => {
          const response: CloudResponse<TreeDefectDetailResponse> = res.data;
          if (response.isSuccess) {
            setDefectDetails(response.data);
          } else {
            showErrorNotification(response.msg ? response.msg : undefined);
          }
        })
        .catch((err) => {
          logUnhandledErrors(err);
          showErrorNotification("Error occurred while processing the request.");
        })
        .finally(() => {
          setDetailsLoading(false);
        });
    }
  };
  const updateDefectDetails = (
    defectStatusId: number,
    observationNotes: string,
    comments: string
  ): void => {
    if (defectDetails?.defectId) {
      api
        .put(`${endpointConfig.UPDATE_TREE_DEFECT_STATUS}`, {
          defectId: defectDetails?.defectId,
          statusId: defectStatusId,
          comment: comments,
          observationNotes: observationNotes,
        })
        .then((res) => {
          const response: CloudResponse<object> = res.data;
          if (response.isSuccess) {
            getDefectDetails(defectDetails?.defectId);
            setFormStatus(true);
            showSuccessNotification("successfully updated.");
            fetchTableData();
          } else {
            showErrorNotification(response.msg ? response.msg : undefined);
          }
        })
        .catch((err) => {
          logUnhandledErrors(err);
          showErrorNotification("Error occurred while processing the request.");
        });
    }
  };
  return (
    <div className="tree-inspection-list">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Title className="sub-text" level={2}>
            Tree Inspections
          </Title>
          <p className="sub-text">
            {estateInfo?.estateName} .&nbsp;
            <Text type="danger">
              {treeInspectionDefectList?.pagination
                ? treeInspectionDefectList.pagination.total
                : "0"}
              &nbsp;Pending JTC Actions
            </Text>
          </p>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col lg={17} md={15} sm={12} xs={24}>
          <CustomCard>
            <CustomTable
              className="tree-inspection-table"
              dataSource={treeInspectionDefectList?.data}
              columns={columns}
              pagination={treeInspectionDefectList?.pagination}
              loading={treeInspectionDefectList?.loading}
              onChange={(pagination, filter, sort) => {
                handleTableChange(
                  pagination,
                  Array.isArray(sort) ? sort[0] : sort,
                  filter
                );
              }}
              size="middle"
              onRow={(record: any) => ({
                onClick: () => {
                  getDefectDetails(record.defectId);
                },
              })}
            />
          </CustomCard>
        </Col>
        <Col lg={7} md={9} sm={12} xs={24}>
          <CustomCard className="defect-detail-card">
            {defectDetails ? (
              <DefectDetail
                defectDetails={transformTreeDefect(
                  defectDetails,
                  setFormStatus,
                  updateDefectDetails,
                  formDisabled,
                  defectStatus,
                  isDetailsLoading
                )}
              ></DefectDetail>
            ) : (
              <Empty
                className="empty-msg"
                description="Click On Row to View Defect"
              ></Empty>
            )}
          </CustomCard>
        </Col>
      </Row>
    </div>
  );
};

export default TreeInspectionList;

import { Col, Row, Select } from "antd";
import Title from "antd/lib/typography/Title";
import { ReactNode, useEffect, useState } from "react";
import api from "../../config/axiosConfig";
import InspectionSumaryCard from "../../components/CustomCard/InspectionSummaryCard";
import MapViewSumaryCard from "../../components/CustomCard/MapViewSummaryCard";
import "./Dashboard.scss";
import endpointConfig from "../../config/endpointConfig";
import { CloudResponse } from "../../types/CloudResponse";
import { EstateResponse } from "../../types/EstateResponse";
import { InspectionSummaryResponse } from "../../types/InspectionSummaryResponse";
import { InspectionTypes } from "../../types/InspectionTypesEnum";
import { EstateInfoResponse } from "../../types/EstateDetailsResponse";
import { showErrorNotification } from "../../components/Notification/Notification";
import { logUnhandledErrors } from "../../helpers/errorLogHelper";
import { AuthService } from "../../services/authService";
import { Button } from "antd";
import { TableOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Option } = Select;

const Dashboard = () => {
	const [estates, setEstates] = useState<EstateResponse[]>([]);
	const [estateId, setEstateId] = useState("");
	const [estateInfo, setEstateInfo] = useState<EstateInfoResponse>();
	const [roadInspectionSummary, setRoadInspectionSummary] =
		useState<InspectionSummaryResponse>();
	// const [treeInspectionSummary, setTreeInspectionSummary] =
	// 	useState<InspectionSummaryResponse>();

	useEffect(() => {
		api
			.get(`${endpointConfig.GET_ESTATES}`)
			.then((res) => {
				const response: CloudResponse<EstateResponse[]> = res.data;
				if (response.isSuccess) {
					setEstates(response.data);
				} else {
					showErrorNotification(response.msg ? response.msg : undefined);
				}
			})
			.catch((err) => {
				logUnhandledErrors(err);
				showErrorNotification("Error occurred while processing the request.");
			});
	}, []);
	useEffect(() => {
		if (estates.length > 0) {
			setEstateId(estates[0].estateId.toString());
		}
	}, [estates]);
	useEffect(() => {
		var unixTimestamp = Math.round(new Date().getTime() / 1000);
		if (estateId) {
			api
				.get(
					`${endpointConfig.GET_ESTATE_DETAILS}`.replace("estate-id", estateId)
				)
				.then((res) => {
					const response: CloudResponse<EstateInfoResponse> = res.data;
					if (response.isSuccess) {
						setEstateInfo(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});
			api
				.get(
					`${endpointConfig.GET_ROAD_INSPECTION_SUMMARY}`
						.replace("estate-id", estateId)
						.replace("time-stamp", unixTimestamp.toString())
				)
				.then((res) => {
					const response: CloudResponse<InspectionSummaryResponse> = res.data;
					if (response.isSuccess) {
						setRoadInspectionSummary(response.data);
					} else {
						showErrorNotification(response.msg ? response.msg : undefined);
					}
				})
				.catch((err) => {
					logUnhandledErrors(err);
					showErrorNotification("Error occurred while processing the request.");
				});

			// api
			// 	.get(
			// 		`${endpointConfig.GET_TREE_INSPECTION_SUMMARY}`
			// 			.replace("estate-id", estateId)
			// 			.replace("time-stamp", unixTimestamp.toString())
			// 	)
			// 	.then((res) => {
			// 		const response: CloudResponse<InspectionSummaryResponse> = res.data;
			// 		if (response.isSuccess) {
			// 			setTreeInspectionSummary(response.data);
			// 		} else {
			// 			showErrorNotification(response.msg ? response.msg : undefined);
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		logUnhandledErrors(err);
			// 		showErrorNotification("Error occurred while processing the request.");
			// 	});
		}
	}, [estateId]);

	return (
		<div className="dashboard">
			<Row gutter={[20, 20]}>
				<Col span={24}>
					<Title className="sub-text" level={2}>
						Welcome, {AuthService.getLoggedInUserName()}
					</Title>
					<Title className="sub-text" level={5}>
						JTC
					</Title>
					<p className="sub-text">Let’s take a look at your inspections.</p>
				</Col>
			</Row>
			<Row gutter={[20, 20]}>
				<Col span={24}>
					<Select
						className="estate-selector"
						bordered={false}
						value={estateId}
						onChange={(value) => setEstateId(value)}
					>
						{estates?.map((x): ReactNode => {
							return (
								<Option key={x.estateId} value={x.estateId.toString()}>
									{x.estate}
								</Option>
							);
						})}
					</Select>
				</Col>
			</Row>
			<Row gutter={[20, 20]}>
				<Col md={16} sm={24} xs={24}>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							{roadInspectionSummary ? (
								<InspectionSumaryCard
									key={1}
									inspectionSummary={roadInspectionSummary}
									type={InspectionTypes.RoadInspection}
									estateId={estateId}
								></InspectionSumaryCard>
							) : (
								<></>
							)}
						</Col>
						{/* <Col span={24}>
              {treeInspectionSummary ? (
                <InspectionSumaryCard
                  key={2}
                  inspectionSummary={treeInspectionSummary}
                  type={InspectionTypes.TreeInspection}
                  estateId={estateId}
                ></InspectionSumaryCard>
              ) : (
                <></>
              )}
            </Col> */}
						<Row>
							<Button
								type="primary"
								value="large"
								className="trip-summary-button"
							>
								<Link to={`/trip-summary-list/${estateId}`}>
									Report Generation <TableOutlined className="action-icon" />
								</Link>
							</Button>
							<Button
								type="primary"
								value="large"
								className="estate-level-view"
							>
								<Link to={`/estate-level-view/${estateId}`}>
									Estate Metrics <TableOutlined className="action-icon" />
								</Link>
							</Button>
						</Row>
					</Row>
				</Col>
				<Col
					md={8}
					sm={24}
					xs={24}
					style={{ display: "flex", alignSelf: "stretch", width: "100%" }}
				>
					<MapViewSumaryCard estateInfrmation={estateInfo!}></MapViewSumaryCard>
				</Col>
			</Row>
		</div>
	);
};

export default Dashboard;

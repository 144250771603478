import { useEffect, useState } from "react";
import { Col, Empty, Row, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { useParams } from "react-router-dom";
import EstateMetricCard from "../../components/EstateMetricCards/EstateMetricCard";
import api from "../../config/axiosConfig";
import "./EstateLevelView.scss";
import { EstateMetricResponse } from "../../types/EstateMetricResponse";
import endpointConfig from "../../config/endpointConfig";
import { showErrorNotification } from "../../components/Notification/Notification";
import { CloudResponse } from "../../types/CloudResponse";
import EstateDefectFixedCard from "../../components/EstateMetricCards/EstateDefectsFixedCard";
import { EstateDefectsFixedResponse } from "../../types/EstateDefectsFixedResponse";

const EstateLevelView = () => {
	const { estateId } = useParams<{ estateId: string }>();
	const [estateMetrics, setEstateMetrics] = useState<EstateMetricResponse[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [estateDefectsFixed, setEstateDefectsFixed] =
		useState<EstateDefectsFixedResponse[]>();

	// fetch the initial data required for the page
	useEffect(() => {
		api
			.get(endpointConfig.GET_ESTATE_METRICS)
			.then((res) => {
				const response: CloudResponse<EstateMetricResponse[]> = res.data;
				setEstateMetrics(response.data);
			})
			.catch((err) => {
				showErrorNotification(err.msg);
			});
		api
			.get(endpointConfig.GET_ESTATE_DEFECTS_FIXED)
			.then((res) => {
				const response: CloudResponse<EstateDefectsFixedResponse[]> = res.data;
				setEstateDefectsFixed(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				showErrorNotification(err.msg);
			});
	}, []);

	return (
		<div>
			{estateId && parseInt(estateId) ? (
				<>
					<div className="estate-level-view">
						<Row gutter={[20, 20]} style={{ marginBottom: 30 }}>
							<Col span={24}>
								<Title className="sub-text" level={2}>
									Estate Level Metrics
								</Title>
							</Col>
						</Row>
						{isLoading ? (
							<div className="flex-container" style={{ marginTop: "10rem" }}>
								<Spin size="large"></Spin>
							</div>
						) : (
							<Row gutter={[20, 20]}>
								<Col xxl={12} xl={18} lg={14} md={14} sm={24} xs={24}>
									{estateMetrics?.map((estateMetric) => {
										return (
											<EstateMetricCard
												EstateMetricProps={estateMetric}
												className="estate-metric-card"
											></EstateMetricCard>
										);
									})}
								</Col>
								<Col xxl={12} xl={6} lg={10} md={10} sm={24} xs={24}>
									<Row>
										<EstateDefectFixedCard
											EstateDefectsFixedProps={estateDefectsFixed!}
										></EstateDefectFixedCard>
									</Row>
								</Col>
							</Row>
						)}
					</div>
				</>
			) : (
				<Empty description="Invalid estate id"></Empty>
			)}
		</div>
	);
};

export default EstateLevelView;
